import create from "zustand";

export type HelpState = {
    open: (index: string) => void,
    close: () => void,
    data:Record<string,string>
    isOpen: boolean,
    index:string,
}


export const useHelp = create<HelpState>((set) => ({
   open:(index)=>{
        set({index,isOpen:true});
   },
   data:{
    "group":"Don't see your group? For each new class that you enroll in, you need to click on the invitation link for that specific class which can be found in your order confirmation email and on your course page (usually schoolofsong.org/teacher-first-name)."
,"welcome":                            "Welcome to the song transmitter! If you're submitting for an assignment, make sure to select the assignment you're submitting for, as well as the group you are in. (Audio uploads only)"

},
    isOpen:false,
    index:'',
    close:()=>{
        set({index:'',isOpen:false});
    }
}));
import firebase from 'firebase/compat/app';

import { GroupId } from '../models/Group'
import { FreshSong, Song, SongId, SongRef } from '../models/Song'
import { SpecifiedDate, Timestamp } from '../models/Timeline'
import { findHashtags, trimToTen } from '../util/sugar'

import { CommentThreadService } from './CommentThreadService'
import { DirectoryService } from './DirectoryService'
import ObserverService from './ObserverService'
import { SearchService } from './SearchService'
import isEmpty from 'lodash.isempty'
import { collection, getCountFromServer, getFirestore } from 'firebase/firestore';
const firestore=firebase.firestore

export class SongService {

  static fetchSongsForRegion = (regionId: string, pageSize: number, offset: number=0): Promise<Song[]> => {
    return new Promise((resolve, reject) => {
      const db = firestore()
      console.log("HAPPENING",regionId,pageSize,offset)
      const query = db.collection('songs')
        .orderBy("updatedAt", "desc")
        .where('regions', 'array-contains', regionId)
        .limit(pageSize)

      if (offset > 0) {
        // Get the last document from the previous page
        db.collection('songs')
          .orderBy("updatedAt", "desc")
          .where('regions', 'array-contains', regionId)
          .limit(offset)
          .get()
          .then((snapshot) => {
            const lastVisible = snapshot.docs[snapshot.docs.length - 1]
              console.log("GOT PAGE")
            // Start after the last document from previous page
            query.startAfter(lastVisible)
              .get()
              .then((querySnapshot) => {
                const songBatch = querySnapshot.docs.map((songDoc) =>
                  SongService.StronglyTypeSongData(songDoc),
                )
                resolve(songBatch)
              })
              .catch((e) => {
                console.log(e)
                reject(e)
              })
          })
          .catch((e) => {
            console.log(e)
            reject(e)
          })
      } else {
        // First page, no offset needed
        query.get()
          .then((querySnapshot) => {
            console.log("Got batch")
            const songBatch = querySnapshot.docs.map((songDoc) =>
              SongService.StronglyTypeSongData(songDoc),
            )
            resolve(songBatch)
          })
          .catch((e) => {
            console.log(e)
            reject(e)
          })
      }
    })
  }

  static observe(
    groups: GroupId[],
    onFreshSong: (song: Song) => void,
    onRevokeSong: (songId: string) => void,
  ) {
    if (groups.length === 0) {
      return
    }
    if (ObserverService.please().exist('latest-song')) {
      console.error('observing already')
      return
    }
    const db = firestore()

    const revokeWatcher = db
      .collection('songs')
      .where('groups', 'array-contains-any', trimToTen(groups))
      .orderBy('updatedAt', 'desc')
      .limit(1)
      .onSnapshot((songQuery) => {
        songQuery.docChanges().forEach(function (change) {
          if (change.type === 'removed') {
            // Lets make sure the song is actually not there...
            SongService.fetch(change.doc.id).catch(() => {
              onRevokeSong(change.doc.id)
            })
          }
        })
      })

    const watcher = db
      .collection('songs')
      .where('groups', 'array-contains-any', trimToTen(groups))
      .orderBy('updatedAt', 'desc')
      .limit(1)
      .onSnapshot((songQuery) => {
        songQuery.docChanges().forEach(function (change) {
          if (change.type === 'added') {
            const freshSong = {
              ...change.doc.data(),
              id: change.doc.id,
            } as Song
            onFreshSong(freshSong)
          }
          if (change.type === 'modified') {
            const freshSong = {
              ...change.doc.data(),
              id: change.doc.id,
            } as Song
            onFreshSong(freshSong)
          }
        })

        for (const songDoc of songQuery.docs) {
          const song = SongService.StronglyTypeSongData(songDoc)
          onFreshSong(song)
        }
      })

    ObserverService.please().add('songs', watcher)
    ObserverService.please().add('songs-revoked', revokeWatcher)
  }

  static toRef(song: Song): SongRef {
    return {
      id: song.id,
      updatedAt: song.updatedAt,
      comments: song.comments || [],
    } as SongRef
  }

  static transmit = async (freshSong: FreshSong): Promise<Song> => {
    const db = firestore()
    const songsDB = db.collection('songs')
    const artists = await Promise.all(
      freshSong.authors.map(async (artistId) =>
        DirectoryService.fetch(artistId),
      ),
    )

    freshSong.artists = {}

    for (const artist of artists) {
      let { id, ownerId, alias, avatar } = artist
      if (avatar === undefined) avatar = ''
      freshSong.artists[id] = { id, ownerId, alias, avatar }
      freshSong.privacy = artist.privacy || freshSong.privacy || 'public'
    }

    const tags = findHashtags(freshSong.description)

    if (tags) freshSong.tags = tags
    if (freshSong.title === ' ') freshSong.title = 'Untitled'

    const songCount = await getCountFromServer(collection(getFirestore(),'songs'));
    const freshSongIndex = songCount.data().count + 1
    if (!freshSong.submission) freshSong.submission = freshSongIndex

    const { id } = await CommentThreadService.initialize()

    return new Promise((resolve, reject) => {
      const songRef = songsDB.doc(id)
      songRef.set(freshSong).then(() => {
        songRef
          .get()
          .then((songDoc) => {
            if (songDoc !== undefined) {
              const song = SongService.StronglyTypeSongData(songDoc)
              SearchService.please().pushSongToIndex(song)
              resolve(song)
            }
          })
          .catch(reject)
      })
    })
  }

  static updateSong = async (
    id: SongId,
    updatedSong: FreshSong,
    updateArtists: boolean = true,
  ) => {
    const freshSong = { ...updatedSong }

    !freshSong.art && delete freshSong.art

    if (updateArtists) {
      freshSong.artists = {}

      const artists = await Promise.all(
        freshSong.authors.map(async (artistId) =>
          DirectoryService.fetch(artistId),
        ),
      )

      for (const artist of artists) {
        const { id, ownerId, alias, avatar } = artist

        freshSong.artists[id] = { id, ownerId, alias, avatar: avatar || '' }
        freshSong.privacy = artist.privacy || updatedSong.privacy || 'public'
      }
    }

    return new Promise<FreshSong>((resolve, reject) => {
      const db = firestore()
      if (id) {
        db.collection('songs')
          .doc(id)
          .set({ ...freshSong }, { merge: true })
          .then(() => {
            resolve(freshSong)
            return freshSong
          })
          .catch((err) => {
            reject(err)
          })
      } else {
        reject()
      }
    })
  }

  static delete = (id: string): Promise<void> => {
    return new Promise<void>(async (resolve) => {
      const db = firestore()
      if (!id || id === '') {
        return
      }
      const { original } = await SongService.fetch(id)
      await db
        .collection('songs')
        .doc(id)
        .delete()
        .then(async () => {
          SearchService.please().removeSongFromIndex(id)
          resolve()
        })
      if (original && typeof original && original !== id) {
        db.collection('songs')
          .where('original', '==', original)
          .get()
          .then((queryResult) => {
            if (queryResult.size === 1) {
              db.collection('songs')
                .doc(original)
                .update({ original: firestore.FieldValue.delete() })
            }
          })
      }
    })
  }

  static getFileExtension = (url: string) => {
    return new Promise<string>(async (resolve, reject) => {
      const songRef = firebase.storage().refFromURL(url)

      const ext = await songRef
        .getMetadata()
        .then()
        .then((data) => data.name.split('.').slice(-1)[0])
        .catch((err) => {
          console.error(err)
          reject(err)
        })

      resolve(ext as any)
      return ext
    })
  }

  static fetch = (id: string) => {
    return new Promise<Song>((resolve, reject) => {
      const db = firestore()
      if (!id || id === '') {
        reject()
      } else {
        db.collection('songs')
          .doc(id)
          .get()
          .then((songDoc) => {
            if (!songDoc.exists) {
              resolve({} as Song)
            }
            resolve(SongService.StronglyTypeSongData(songDoc))
          })
          .catch(reject)
      }
    })
  }

  static fetchSome = (limit: number, groupIds: string[]): Promise<Song[]> => {
    return new Promise((resolve, reject) => {
      const db = firestore()
      db.collection('songs')
        .where('groups', 'array-contains-any', groupIds.reverse().slice(0, 10))
        .orderBy('updatedAt')
        .limitToLast(limit)
        .get()
        .then((querySnapshot) => {
          const songBatch = querySnapshot.docs.map((songDoc) => {
            return SongService.StronglyTypeSongData(songDoc)
          })
          resolve(songBatch)
        })
        .catch(reject)
    })
  }

  static fetchSongsOnDate = (groups: string[], date: string) => {
    return new Promise<Song[]>((resolve, reject) => {
      const db = firestore()
      const dayBegin = new Date(date + 'T00:00:00')
      const dayEnd = new Date(date + 'T23:59:59')

      db.collection('songs')
        .where('groups', 'array-contains-any', trimToTen(groups))
        .orderBy('updatedAt')
        .where('updatedAt', '>', dayBegin)
        .where('updatedAt', '<', dayEnd)
        .get()
        .then((querySnapshot) => {
          const songBatch = querySnapshot.docs.map((songDoc) => {
            return SongService.StronglyTypeSongData(songDoc)
          })
          resolve(songBatch)
        })
        .catch(reject)
    })
  }

  static fetchMore = (
    groups: string[],
    limit: number,
    timestamp: Timestamp,
  ): Promise<Song[]> => {
    if (timestamp.seconds === -1) {
      return SongService.fetchSome(limit, groups)
    }
    return new Promise((resolve, reject) => {
      const db = firestore()
      db.collection('songs')
        .where('groups', 'array-contains-any', trimToTen(groups))
        .orderBy('updatedAt')
        .endBefore(timestamp)
        .limitToLast(limit)
        .get()
        .then((querySnapshot) => {
          const songBatch = querySnapshot.docs.map((songDoc) =>
            SongService.StronglyTypeSongData(songDoc),
          )
          resolve(songBatch)
        })
        .catch(reject)
    })
  }

  static fetchSongsForAssignment = (assignmentId: string): Promise<Song[]> => {
    return new Promise((resolve, reject) => {
      const db = firestore()
      db.collection('songs').orderBy("updatedAt","desc")
        .where('assignment', '==', assignmentId)
        .get()
        .then((querySnapshot) => {
          const songBatch = querySnapshot.docs.map((songDoc) =>
            SongService.StronglyTypeSongData(songDoc),
          )
          resolve(songBatch)
        })
        .catch((e)=>{
console.log(e)
reject(e);
        })
    })
  }

  /**
   * Fetch some songs with a particular hashtag
   *
   * @param tag the hashtag to query
   * @param limit how many songs to get
   */
  static fetchSongsWithHashtag = (tag: string, groups: string[]) => {
    return new Promise<Song[]>((resolve, reject) => {
      const db = firestore()
      db.collection('songs')
        .where('tags', 'array-contains', tag)
        .orderBy('updatedAt', 'desc')
        .limit(10)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            resolve([])
          }
          const songBatch = querySnapshot.docs.map((songDoc) => {
            return SongService.StronglyTypeSongData(songDoc)
          })
          resolve(songBatch)
        })
        .catch(reject)
    })
  }

  /**
   * Fetch some songs with a particular hashtag
   *
   * @param tag the hashtag to query
   */
  static fetchSongsWithHashtagInComments = (tag: string) => {
    return new Promise<SongRef[]>((resolve, reject) => {
      const db = firebase.firestore()
      db.collection('threads')
        .where('tags', 'array-contains', tag)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            resolve([])
          }
          const songBatch = querySnapshot.docs.map((x) => {
            const updatedAt: Timestamp = { seconds: 0, nanoseconds: 0 }
            return { id: x.id, updatedAt }
          })
          resolve(songBatch)
        })
        .catch(reject)
    })
  }


  /**
   * Fetch some songs with a particular hashtag
   *
   * @param tag the hashtag to query
   */
  static sortSongs = (assignments: string[],groups:string[]) => {
    return new Promise<SongRef[]>((resolve, reject) => {
      const db = firebase.firestore()
      let query:any= db.collection('songs')
      
      if(assignments){
        query=query.where('assignment', 'in', assignments)
      }
      if(groups){
        query=query.where('group', 'array-contains', trimToTen(groups))
      }
      
      query.get()
        .then((querySnapshot:any) => {
          if (querySnapshot.empty) {
            resolve([])
          }
          const songBatch = querySnapshot.docs.map((x:any) => {
            const updatedAt: Timestamp = { seconds: 0, nanoseconds: 0 }
            return { id: x.id, updatedAt }
          })
          resolve(songBatch)
        })
        .catch(reject)
    })
  }
  

  static fetchSongsByParams = (
    groups: string[],
    assignments: string[],
    dateOrder: string,
    isShowLowComments: boolean,
    specifiedDate: SpecifiedDate,
    isRandomSongs: boolean,
    fetchMore: boolean,
    lastVisibleSong: SongId,
  ) => {
    return new Promise<Song[]>(async (resolve, reject) => {
      const db = firestore()
      console.log( groups,
        assignments,
        dateOrder,
        isShowLowComments,
        specifiedDate,
        isRandomSongs,
        fetchMore,
        lastVisibleSong)
      const songsDoc = db.collection('songs')

      // Get all sort fields and turn them into fields for firebase
      const isSpecifiedDate = dateOrder === 'specified'
      const sortedByDate = dateOrder === 'new' ? 'desc' : 'asc'

      // Define the documents by which we will search
      const currentDocument = isEmpty(assignments)
        ? songsDoc.where('groups', 'array-contains-any', trimToTen(groups))
        : songsDoc.where('assignment', 'in', assignments)

      // We divide the document in half depending on the song random id.
      // Then we determine (depending on the last received song) from which part of the part we will receive songs

      // Choose which method to sort this data
      const specifiedByDate = currentDocument
        .where(
          'updatedAt',
          '<=',
          new Date(specifiedDate.year, specifiedDate.month + 1, 0),
        )
        .where(
          'updatedAt',
          '>=',
          new Date(specifiedDate.year, specifiedDate.month),
        )

      const orderedSongs = isShowLowComments
        ? currentDocument.where('commentsNumber', '<', 3)
        : currentDocument.orderBy('updatedAt', sortedByDate)
      const currentSongsBySortType = isSpecifiedDate
        ? specifiedByDate
        : orderedSongs

      // Depending on the type of action, we download the first 10 songs,
      // or the next 10 based on the last downloaded song in the collection
      const lastVisibleItem =
        !isEmpty(lastVisibleSong) && (await songsDoc.doc(lastVisibleSong).get())

      const fetch = () => currentSongsBySortType.limit(10)
      const fetchMoreSongs = () =>
        currentSongsBySortType.startAfter(lastVisibleItem).limit(10)
      const typeOfFetch = fetchMore ? fetchMoreSongs() : fetch()

      typeOfFetch
        .get()
        .then(async (querySnapshot) => {
          const songsData: Song[] = []

          await querySnapshot.docs.forEach((songDoc) =>
            songsData.push(SongService.StronglyTypeSongData(songDoc)),
          )

          // It is necessary in order to switch the receipt of random songs to the second part,
          // if we received all of them from the first part

         return resolve(songsData)
        })
        .catch((err) => {
          console.error(err, 'filtered songs are empty')
          return reject()
        })
    })
  }

  static StronglyTypeSongData = (
    songDoc:
      | firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
      | firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>,
  ) => {
    return { ...songDoc.data(), id: songDoc.id } as Song
  }
}

import React, { useEffect } from 'react';
import { Canvas } from 'react-three-fiber';
import * as THREE from "three";
import Content from '../components/Content';
import { usePlayer } from '../data/store/usePlayer';
import { useProfile } from '../data/store/useProfile';
import MediaService from '../services/MediaService';
import Swarm from '../components/Swarm';
import { usePlaylist } from '../data/store/usePlaylist';


const Visualizer: React.FC = () => {
    const { darkMode } = useProfile();
    const { isPaused, } = usePlayer();

    const analysis = MediaService.please().initializeAnalysis() ? "active" : "inactive";
    const initialize = () => { MediaService.please().initializeAnalysis(); }
    const maxHeight = `${70}vh`;
    const canvasStyle = {
        width: '100%',
        height: '100%',
        maxHeight,
        objectFit: 'contain' as const // Changed from 'cover' to 'contain' to prevent cropping
      };
    
      useEffect(()=>{

        initialize();
      })
    return (
            <Canvas style={canvasStyle}
                gl={{ alpha: false, antialias: false, logarithmicDepthBuffer: true }}
                camera={{ fov: 75, position: [0, 0, 70] }}
                onCreated={({ gl }) => {
                    gl.setClearColor(darkMode ? 'black' : 'white')
                    gl.toneMapping = THREE.ACESFilmicToneMapping
                    gl.outputEncoding = THREE.sRGBEncoding
                }}>
                <ambientLight intensity={1} />
                {analysis === "active" && <Swarm />}
            </Canvas>
    );
};



export default (Visualizer);

import React, { useState } from 'react';
import {
  IonSearchbar,
  IonList,
  IonItem,
  IonLabel,
  IonContent,
  IonModal,
  IonButton,
  IonChip,
} from '@ionic/react';
import { useRegions } from '../data/store/useRegions';

interface RegionSelectorProps {
  value: string;
  onChange: (regionId: string) => void;
  placeholder?: string;
}

const RegionSelector: React.FC<RegionSelectorProps> = ({ 
  value, 
  onChange,
  placeholder = "Select a region" 
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const regions = useRegions(state => state.regions);
  
  // Get the currently selected region name
  const selectedRegion = value&&regions[value]?.id || placeholder;
  
  // Filter regions based on search text
  const filteredRegions = Object.entries(regions)
    .filter(([_, region]) => 
      region.city.toLowerCase().includes(searchText.toLowerCase())
    );

  return (
    <>
      <IonItem onClick={() => setIsOpen(true)} button>
        <IonLabel>{regions[selectedRegion]?.city||placeholder}</IonLabel>
      </IonItem>

      <IonModal isOpen={isOpen} onDidDismiss={() => setIsOpen(false)}>
        <IonContent>
          <div className="p-4">
            <IonSearchbar
              value={searchText}
              onKeyDown={(e)=>{
                setSearchText(e.currentTarget.value as string);
              }}
              onChange={(e)=>{
                setSearchText(e.currentTarget.value as string);
              }}
              placeholder="Search regions..."
              className="mb-4"
            />

            <IonList>
              {filteredRegions.map(([id, region]) => (
                <IonItem 
                  key={id}
                  button
                  onClick={() => {
                    onChange(id);
                    setIsOpen(false);
                  }}
                >
                  <IonLabel>{region.city}</IonLabel>
                  {value === id && (
                    <IonChip color="primary" slot="end">
                      Selected
                    </IonChip>
                  )}
                </IonItem>
              ))}
            </IonList>

            {filteredRegions.length === 0 && (
              <div className="p-4 text-center text-gray-500">
                No regions found
              </div>
            )}
          </div>

          <div className="p-4 border-t">
            <IonButton 
              expand="block"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </IonButton>
          </div>
        </IonContent>
      </IonModal>
    </>
  );
};

export default RegionSelector;
import {
  IonCardContent,
  IonCol,
  IonGrid,
  IonLoading,
  IonRow
} from "@ionic/react";
import React, { useEffect, useState } from "react";

import Content from "../components/Content";
import PleaseLoginCard from "../components/PleaseLoginCard";
import RequestAccessCard from "../components/RequestAccessCard";

import { useConfig } from "../data/store/useConfig";
import { useProfile } from "../data/store/useProfile";
import { useSongs } from "../data/store/useSongs";

import isEmpty from "lodash.isempty";
import { CompactToggle } from "../components/CompactToggle";
import SortSongs from "../components/SortSongs";
import "../components/styles.scss";
import { usePageTitle } from "../util/sugar";
import useWindowSize from "../util/useWindowSize";
const SongsPage: React.FC = () => {
  const { authorId, status: userStatus } = useProfile();
  const {
    fetchSome,
    status,
    initialFetchStatus,
    sortedSongs,
    setInitialSortedSongs,
  } = useSongs();
  const { isAlumni, activeMemberships } = useConfig();
  const { width } = useWindowSize();

  const [isShowForm,] = useState(true);

  const membership = activeMemberships();
  const unauthenticated = userStatus === "unauthenticated";
  const authenticated = userStatus === "authenticated";
  // const songStatus = !isEmpty(membership) || !isEmpty(groups) ? status : "idle";
  const lg = width > 767;

  usePageTitle("Songs");

  // const handleToggleMenu = async () => {
  //   setIsShowForm((prevState) => !prevState);

  //   if (isShowForm) {
  //     clearSortedSongs();
  //   } else if (lg && !isShowForm) {
  //     await menuController.enable(true, "sortSongs");
  //     await menuController.open("sortSongs");
  //   }
  // };

  // const handleFetchMore = () => {
  //   fetchSome(membership);
  // };

  useEffect(() => {
    if (isEmpty(sortedSongs)) {
      setInitialSortedSongs();
    }
  }, [isShowForm]);

  useEffect(() => {
    if (
      authenticated &&
      initialFetchStatus === "initial" &&
      !isEmpty(membership)
    ) {
      fetchSome(membership);
    }
  }, [authenticated, initialFetchStatus, membership, fetchSome]);

  if (isAlumni() === false) {
    return (
      <Content>
        <RequestAccessCard authorId={authorId} />
      </Content>
    );
  } else if (unauthenticated) {
    return (
      <Content>
        <PleaseLoginCard action="View Songs" />
      </Content>
    );
  }

  return (
    <Content>
      {status === "fetching" && <IonLoading isOpen />}

      <IonGrid>
        <IonRow>
          <IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
            <IonCardContent
              style={{
                padding: "5px",
                borderRadius: "8px",
                margin: "0 16px",
              }}
            >
              <CompactToggle />
            </IonCardContent>

            {isEmpty(membership) && (
              <RequestAccessCard message="You have no active classes" />
            )}

          </IonCol>
        </IonRow>
        <SortSongs />
      </IonGrid>
    </Content>
  );
};

export default SongsPage;

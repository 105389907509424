import React, {useState} from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonProgressBar,
} from "@ionic/react";
import {
  ellipsisHorizontalOutline,
  pause as pauseIcon,
  play as playIcon,
  playBack,
  playForward,
  removeOutline,
} from "ionicons/icons";

import {usePlayer} from "../data/store/usePlayer";
import {usePlaylist} from "../data/store/usePlaylist";
import {useSongs} from "../data/store/useSongs";
import MediaService from "../services/MediaService";

import {Song} from "../models/Song";
import AvailablePlaylists from "./AvailablePlaylists";

interface PlaylistProps {
}

const Playlist: React.FC<PlaylistProps> = () => {
  const {
    setNowPlaying,
    nextSong,
    previousSong,
    removeSong,
    songs,
    nowPlayingId,
  } = usePlaylist();
  const {isPaused} = usePlayer();
  const {retrieve} = useSongs();
  const [popoverState, setShowPopover] = useState(false);

  const popoverDismiss = () => {
    setShowPopover(false);
  };

  const playSongNow = (id: string) => {
    const song = retrieve(id);
    song &&
    MediaService.please()
      .setSong(song)
      .then(async () => {
        await MediaService.please().play();
        setNowPlaying(id);
      });
  };

  const setAndPlay = (song: Song) => {
    MediaService.please()
      .setSong(song)
      .then(async () => {
        await MediaService.please().play();
      });
  };

  const playPrev = () => {
    const song = retrieve(previousSong());
    song && setAndPlay(song);
  };

  const playNext = () => {
    const song = retrieve(nextSong());
    song && setAndPlay(song);
  };

  function renderPlayList(songs: string[]) {
    return songs.map((songId) => {
      const song = retrieve(songId);
      if (!song) {
        return (
          <IonItem>
            <IonProgressBar type="indeterminate"/>
          </IonItem>
        );
      }

      return (
        <IonItemSliding key={song.id}>
          <IonItem
            color={nowPlayingId === song.id ? "primary" : undefined}
            onClick={() => playSongNow(song.id)}
          >
            <IonLabel>{song.title}</IonLabel>
          </IonItem>
          <IonItemOptions side="end">
            <IonItemOption
              color="danger"
              onClick={() => {
                if (songs.length === 1) MediaService.please().pause();
                removeSong(song.id);
                if (nowPlayingId === song.id) nextSong();
              }}
            >
              <IonIcon icon={removeOutline}/>
            </IonItemOption>
          </IonItemOptions>
        </IonItemSliding>
      );
    });
  }

  return (
    <IonMenu side="end" menuId="playlist" type="overlay" contentId="main">
      <IonContent forceOverscroll={false}>
        <IonList lines="none">
          <IonListHeader>
            <IonLabel>Playlist</IonLabel>
            <IonButton
              onClick={(event: any) => {
                // event.persist();
                setShowPopover( true);
              }}
            >
              <IonIcon
                size="large"
                color="dark"
                icon={ellipsisHorizontalOutline}
              />
            </IonButton>

            
              <AvailablePlaylists
                popoverState={popoverState}
                popoverDismiss={popoverDismiss}
                nowPlayingId={nowPlayingId}
              />
          </IonListHeader>

          {renderPlayList(songs)}
        </IonList>
      </IonContent>

      <IonFooter>
        <IonList lines="none" style={{textAlign: "center"}}>
          <IonItem style={{textAlign: "center"}}>
            <IonButtons>
              {songs.length > 1 && (
                <IonButton onClick={playPrev}>
                  <IonIcon icon={playBack}/>
                </IonButton>
              )}

              {isPaused && nowPlayingId && (
                <IonButton onClick={() => MediaService.please().play()}>
                  <IonIcon icon={playIcon}/>
                </IonButton>
              )}

              {!isPaused && nowPlayingId && (
                <IonButton onClick={() => MediaService.please().pause()}>
                  <IonIcon icon={pauseIcon}/>
                </IonButton>
              )}

              {songs.length > 1 && (
                <IonButton onClick={playNext}>
                  <IonIcon icon={playForward}/>
                </IonButton>
              )}
            </IonButtons>
          </IonItem>
        </IonList>
      </IonFooter>
    </IonMenu>
  );
};

export default React.memo(Playlist);

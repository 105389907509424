import create from "zustand";
import { Tag } from "../../models/Tag";
import { Region, RegionId } from "../../models/Region";
import regions from "./airports.json"
export type TagState = {
    regions: Record<RegionId, Region>;
}

export const useRegions = create<TagState>((set, store) => ({
    regions
    }
));
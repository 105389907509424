import React, { FC, useEffect, useRef } from 'react';
import { IonButton } from '@ionic/react';
import MediaService from '../services/MediaService';
import { usePlayer } from '../data/store/usePlayer';
import { usePlaylist } from '../data/store/usePlaylist';
import { useSongs } from '../data/store/useSongs';
import Visualizer from '../pages/Visualizer';


interface PlayerProps {
  onClick:()=>void
}


const Player: FC<PlayerProps> = ({onClick}) => {
  const { playNow, pauseNow, songIsOver} = usePlayer();
  const { getNextSongIfAvailable ,nowPlayingId} = usePlaylist();
  const { retrieve } = useSongs();
  const playerRef = useRef<HTMLVideoElement>(null);
  const nowPlaying = nowPlayingId && retrieve(nowPlayingId);
  useEffect(() => {
    if (playerRef.current) {
      MediaService.please().setPlayerRef(playerRef);
      document.addEventListener('fullscreenchange', () => {
        // document.fullscreenElement will point to the element that
        // is in fullscreen mode if there is one. If there isn't one,
        // the value of the property is null.
        setTimeout(() => {
          MediaService.please().play();
        }, 500);
      });
    }
  }, [playerRef]);

  return (
    <IonButton onClick={onClick}>
      <video
        playsInline
        onEnded={() => {
          songIsOver();
          const nextSong = retrieve(getNextSongIfAvailable());
          nextSong && MediaService
            .please()
            .setSong(nextSong)
            .then(() => MediaService.please().play());
        }}
        onPlay={() => playNow()}
        onPause={() => pauseNow()}
        controlsList='nodownload'
        id='sadfam-media'
        crossOrigin='anonymous'
        style={{ height: '100%' }}
        ref={playerRef}
      />
    </IonButton>
  );
}

export default React.memo(Player);

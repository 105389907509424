import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
} from "@ionic/react";
import React, { useEffect, useMemo } from "react";

import { useRoles } from "../data/store/useRoles";

import isEmpty from "lodash.isempty";
import { Role, RoleType } from "../models/Role";

import { AsyncArtistByIdButton } from "./AsyncArtistButton";
import ChangeUserRole from "./ChangeUserRole";

export const RolesPanel: React.FC = () => {
  const { getUsersWithRoles, allUsersWithRoles, role } = useRoles();

  const getUsers = (roles: RoleType) =>
    allUsersWithRoles.filter((item: Role) => item.authority.includes(roles));
  const allSuperAdmins = getUsers("super admin");
  const allAdmins = getUsers("admin");
  const allModerators = getUsers("moderator");

  const authority = role && role.authority;
  const isSuperAdmin = authority && authority.includes("super admin");
  const isAdmin = authority && authority.includes("admin");
  useEffect(() => {
    getUsersWithRoles();
  }, [getUsersWithRoles]);

  const superAdmins = useMemo(
    () =>
      allSuperAdmins.map((item: Role) => {
        return (
          <IonItem key={item.ownerId}>
            <IonLabel>
              <AsyncArtistByIdButton variant="alias" id={item.id} />
            </IonLabel>
            {isSuperAdmin && (
              <IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
                <ChangeUserRole id={item.id} />
              </IonCol>
            )}
          </IonItem>
        );
      }),
    [allSuperAdmins,isSuperAdmin]
  );
  return (
    <IonCard>
      <IonCardHeader color="primary">
        <IonCardTitle color="secondary">Permissions</IonCardTitle>
      </IonCardHeader>

      <IonCardContent>
        {!isEmpty(allSuperAdmins) && (
          <IonList>
            <IonCardSubtitle style={{ margin: "10px 0" }}>
              Super Admins:
            </IonCardSubtitle>
            {superAdmins}
            <IonItemDivider color={"clear"} />
          </IonList>
        )}

        {!isEmpty(allAdmins) && (
          <IonList>
            <IonCardSubtitle style={{ margin: "10px 0" }}>
              Admins:
            </IonCardSubtitle>
            {allAdmins.map((item: Role) => {
              return (
                <IonItem key={item.ownerId}>
                  <IonLabel>
                    <AsyncArtistByIdButton variant="alias" id={item.id} />
                  </IonLabel>
                  {isSuperAdmin && (
                    <IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
                      <ChangeUserRole id={item.id} />
                    </IonCol>
                  )}
                </IonItem>
              );
            })}
            <IonItemDivider color={"clear"} />
          </IonList>
        )}

        {!isEmpty(allModerators) && (
          <IonList>
            <IonCardSubtitle style={{ margin: "10px 0" }}>
              Moderators:
            </IonCardSubtitle>
            {allModerators.map((item: Role) => {
              return (
                <IonItem key={item.ownerId}>
                  <IonLabel>
                    <AsyncArtistByIdButton variant="alias" id={item.id} />
                  </IonLabel>

                  {(isSuperAdmin || isAdmin) && (
                    <IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
                      <ChangeUserRole id={item.id} />
                    </IonCol>
                  )}
                </IonItem>
              );
            })}
            <IonItemDivider color="clear" />
          </IonList>
        )}
      </IonCardContent>
    </IonCard>
  );
};

export default RolesPanel;

import React, { useEffect, useLayoutEffect, useState } from "react";
import { useParams } from "react-router";
import {
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonItem,
  IonRow,
  IonSpinner,
} from "@ionic/react";
import { useRegions } from "../data/store/useRegions";
import { useConfig } from "../data/store/useConfig";
import { useSongs } from "../data/store/useSongs";

import Content from "./Content";
import { usePageTitle } from "../util/sugar";
import isEmpty from "lodash.isempty";
import { useProfile } from "../data/store/useProfile";
import CompactSongList from "./CompactSongList";

export const RegionSongs: React.FC = () => {
  const { compact } = useProfile();
  const { id, regionId } = useParams<{id:string, regionId:string}>();
  const  {regions}= useRegions()
  const { status, songsTotal, songs, fetchSongsForRegion,regionSongs, } = useSongs();
  const region = regions[regionId];
  const groups = useConfig((x) => x.activeMemberships());
  const isAlumni = useConfig((x) => x.isAlumni());
  const [fetched, setFetched] = useState<string>();
  const [page, setPage] = useState<number>(1);
    const songsForRegion= regionSongs[regionId];
  useEffect(()=>{
        fetchSongsForRegion(regionId)
    },[regionId])

  const refreshPage = () => {
    window.location.reload();
  };

  const noMoreSongs = (Object.keys(songs)||[]).length === songsTotal;

  usePageTitle(region ? region.city : "Loading");

  useLayoutEffect(() => {
    if (!regionId) {
      refreshPage();
    }
  }, [regionId]);


  useEffect(() => {
    if (status === "fetching") return;

    const fetchNext = regionId + "-" + page;

    if (
      typeof id !== "undefined" &&
      typeof regionId !== "undefined" &&
      fetched !== fetchNext
    ) {
      setFetched(fetchNext);
      fetchSongsForRegion(regionId);
    }
    // eslint-disable-next-line
  }, [
    id,
    groups,
    fetchSongsForRegion,
    status,
    fetched,
    isAlumni,
    regionId,
  ]);

  return (
    <Content classname="chill">
        <IonButton  expand="full" fill="clear" routerLink={"/region-artists/"+regionId}>View Artists</IonButton>
      <IonGrid>
        <IonRow>
          {status === "fetching" && (
            <IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
              <IonRow className="ion-justify-content-center">
                <IonSpinner name="dots" />
              </IonRow>
            </IonCol>
          )}

          {status === "idle" &&
            regionSongs &&
            (songsForRegion?songsForRegion:[]).length === 0 && (
              <IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
                <IonCard>
                  <IonItem>
                    No songs found for this region ~
                    <IonButton onClick={refreshPage}>Refresh</IonButton>
                  </IonItem>
                </IonCard>
              </IonCol>
            )}
        </IonRow>

        <IonRow>
          {!isEmpty(songsForRegion) && <IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
            {<CompactSongList 
              status={status}
              songRefs={songsForRegion}
              fetchMore={()=>{        fetchSongsForRegion(regionId)
              }}
              hideFetchButton={noMoreSongs}/>}            
          </IonCol>}
        </IonRow>
      </IonGrid>
    </Content>
  );
};

import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import React from "react";
import { useHelp } from "../data/store/useHelp";

export const HelpModal: React.FC = () => {
  const { close, index, isOpen, data } = useHelp();
  const title = index.replaceAll("-", " ");
  const content = data[index];
  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={() => {
        close();
      }}
      children={
        <IonPage>
          <IonHeader>
            <IonToolbar color="tertiary">
              <IonTitle>{title}</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  onClick={() => {
                    close();
                  }}
                  color="light"
                  fill="clear"
                >
                  <IonIcon icon={closeOutline} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonCard>
              <IonCardContent>{content}</IonCardContent>
            </IonCard>
          </IonContent>
          <IonButton
            fill="clear"
            expand="full"
            onClick={() => {
              close();
            }}
          >
            OK
          </IonButton>
        </IonPage>
      }
    ></IonModal>
  );
};

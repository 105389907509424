import { IonCol, IonGrid, IonLoading, IonRow, useIonViewWillEnter } from "@ionic/react";
import React, { useEffect, useState } from "react";

import Content from "../components/Content";
import PleaseLoginCard from "../components/PleaseLoginCard";
import RequestAccessCard from "../components/RequestAccessCard";
import SongList from "../components/SongList";

import { useConfig } from "../data/store/useConfig";
import { useProfile } from "../data/store/useProfile";
import { useSongs } from "../data/store/useSongs";

import firebase from 'firebase/compat/app';
import isEmpty from "lodash.isempty";
import CompactSongList from "../components/CompactSongList";
import { CompactToggle } from "../components/CompactToggle";
import { app } from "../services/LoginService";
import { useIdParam, usePageTitle } from "../util/sugar";

const GroupSongsPage: React.FC = (props) => {
  const id = useIdParam(props);
  const { observe } = useSongs();
  const { authorId, ownerId, compact } = useProfile();
  const { groups } = useConfig();
  const unauthenticated = useProfile((x) => x.status === "unauthenticated");
  const authenticated = useProfile((x) => x.status === "authenticated");
  const fetchSome = useSongs((x) => x.fetchSome);
  const status = useSongs((x) => x.status);
  const songs = useSongs((x) => x.timeline);
  const membership = useConfig((x) => x.activeMemberships());
  const hasGroups = useConfig(
    (x) => x.membership.length !== 0 && typeof x.membership !== "undefined"
  );
  const loadingGroups = useConfig((x) => typeof x.membership === "undefined");
  const retrieveSong = useSongs((x) => x.retrieve);

  const [fetchedInitial, setFetchedInitial] = useState(false);

  const groupSongs = songs.filter((songRef) => {
    const song = retrieveSong(songRef.id);
    return song && song.groups.includes(id);
  });

  const isMember =
    (!isEmpty(id) && membership.includes(id)) ||
    (groups[id] && groups[id].ownerId === ownerId);

  usePageTitle("Session Songs");
  useIonViewWillEnter(() => {
    firebase.analytics(app).logEvent("view_song", { songId: id })
  }, [])

  useEffect(() => {
    if (authenticated && hasGroups && id && !fetchedInitial && isMember) {
      observe([id]);
      fetchSome([id]);
      setFetchedInitial(true);
    }
  }, [
    isMember,
    authenticated,
    fetchSome,
    hasGroups,
    id,
    membership,
    observe,
    fetchedInitial,
  ]);

  if ((!hasGroups && !loadingGroups) || !isMember) {
    return (
      <Content>
        <RequestAccessCard authorId={authorId} />
      </Content>
    );
  } else if (unauthenticated) {
    return (
      <Content>
        <PleaseLoginCard action="" />
      </Content>
    );
  }

  return (
    <Content className="no-scroll">
      <IonLoading isOpen={status === "initial"} />


      <IonGrid>
        <IonRow>
          <IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
            <CompactToggle />
            {compact ? <CompactSongList status={status} songRefs={groupSongs} fetchMore={() => fetchSome([id])} /> :
              <SongList
                status={status}
                fetchMore={() => fetchSome([id])}
                songRefs={groupSongs}
              />}
          </IonCol>
        </IonRow>
        <IonRow></IonRow>
      </IonGrid>
    </Content>
  );
};

export default GroupSongsPage;

import React, { useEffect, useState } from "react";
import {
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonFooter,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonListHeader,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonTitle,
} from "@ionic/react";

import { useConfig } from "../data/store/useConfig";
import { useProfile } from "../data/store/useProfile";

import { Group } from "../models/Group";
import { uniq, uuidv4 } from "../util/sugar";

import AsyncArtistButton from "./AsyncArtistButton";
import GroupForm from "./GroupForm";
import GroupInviteChip from "./GroupInviteChip";
import { useAssignments } from "../data/store/useAssignments";
import { useSongs } from "../data/store/useSongs";
import { SongService } from "../services/SongService";
import { AssignmentService } from "../services/AssignmentService";
import { Song } from "../models/Song";
import { Assignment } from "../models/Assignment";
import { ArtistService } from "../services/ArtistService";

/**
 * Updated CSV download function using batched artist fetching
 */
const downloadCSV = async (songs: Song[], assignmentTitle: string, members: string[]) => {
  try {
    // Create CSV headers
    const headers = ["Artist", "Title", "Song Link", "Status"];
    
    // Extract all unique ownerIds from submitted songs
    const uniqueOwnerIds = [...new Set(songs.map(song => song.ownerId))];
    
    // Fetch all artists in batches (both for submitted songs and members)
    const allOwnersToFetch = [...new Set([...uniqueOwnerIds])];
    const artists = [...await ArtistService.fetchMultipleByOwnerIds(allOwnersToFetch),...await ArtistService.fetchMultipleByArtistIds(members)];
    // Create a map of ownerId to artist for quick lookup
    const artistMap = new Map(
      artists.map(artist => [artist.ownerId, artist])
    );

    // Create rows for submitted songs
    const submittedRows = songs.map(song => [
      artistMap.get(song.ownerId)?.alias || "Untitled",
      song.title || "Untitled",
      song.media.src || "No link available",
      "Submitted"
    ]);

    // Find members who haven't submitted
    const submittedOwnerIds = new Set(uniqueOwnerIds);
    const missingRows = artists.map(x=>x.ownerId)
      .filter(memberId => !submittedOwnerIds.has(memberId))
      .map(memberId => [
        artistMap.get(memberId)?.alias || "Unknown",
        "No submission",
        "No link",
        "Missing"
      ]);

    // Combine headers and all rows
    const csvContent = [
      headers.join(","),
      ...submittedRows.map(row => row.join(",")),
      ...missingRows.map(row => row.join(","))
    ].join("\n");

    // Create and trigger download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `${assignmentTitle.replace(/[^a-z0-9]/gi, '_')}_songs.csv`);
    
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error generating CSV:", error);
    throw error;
  }
};const GroupItem: React.FC<{ group: Group; onComplete: () => void }> = ({
  group,
  onComplete,
}) => {
  const { id, title, description, invites, members } = group;
  const [editing, setEditing] = useState(false);
  const [highlight, setHighlight] = useState("");
  const [showUsers, setShowUsers] = useState(false);
  const {  assignmentsInGroup } = useAssignments();
  const [groupSongs, setGroupSongs] = useState<Song[]>([]);
  const [assignments, setAssignments] = useState<Assignment[]>([]);
  useEffect(() => {
    editing 
    if(!editing){
      return;
    }
    console.log("get")
    AssignmentService.Fetch([id], (_, assignmentsInfo) => {
      console.log("got");
      const assignments = Object.values(assignmentsInfo);
      setAssignments(assignments);
      
      // Create an array of promises for all song fetch operations
      const songPromises = assignments.map(assignment => 
        SongService.fetchSongsForAssignment(assignment.id)
      );
    
      // Wait for all promises to resolve and then update state once
      Promise.all(songPromises)
        .then(songsArrays => {
          // Flatten the array of arrays into a single array of songs
          const allSongs = songsArrays.flat();
          setGroupSongs(prevSongs => [...prevSongs, ...allSongs]);
        })
        .catch(error => {
          console.error('Error fetching songs:', error);
        });
    });
    }, [editing]);
  return editing ? (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>{title}
        {groupSongs.length!=0&&<IonBadge color='success'>
        {groupSongs.length}
        </IonBadge>}

        </IonCardTitle>
        </IonCardHeader>
      <IonCardContent>
        <IonList>
          {assignments
            .filter((x) => x.title != "")
            .map((x) => (
              <IonItem>
                {x.title}
                {groupSongs.filter((s) => x.id === s.assignment).length!==0&&<IonBadge color='success'>
                {groupSongs.filter((s) => x.id === s.assignment).length}
                </IonBadge>}
                <IonButtons slot="end">
                  <IonButton
                    routerLink={"/assignment-songs/" + id + "/" + x.id}
                    color="success"
                    fill="solid"
                    target="_blank"
                  >
                    Listen
                  </IonButton>
                </IonButtons>
              </IonItem>
            ))}
        </IonList>
        <IonFooter>
          <IonButton
            expand="full"
            fill="clear"
            onClick={() => {
              setEditing(false);
            }}
          >
            Done
          </IonButton>
        </IonFooter>
      </IonCardContent>
    </IonCard>
  ) : (
    <IonItem
      onClick={() => {
        setEditing(true);
      }}
    >
      {title}
    </IonItem>
  );
};

const TeacherAdmin: React.FC = () => {
  const { createGroup, fetchAllGroups } = useConfig();
  const { ownerId, authorId } = useProfile();
  const [newGroupTitle, setNewGroupTitle] = useState("");
  const [addingNewGroup, setIsAddingNewGroup] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [listState, setListState] = useState<string>("active");
  const [groups, setGroups] = useState<Group[]>([]);
  const [query, setQuery] = useState<string>("");
  const { observeGroup } = useAssignments();

  useEffect(() => {
    if (addingNewGroup) {
      return;
    }
    fetchAllGroups(ownerId).then((groupsFetched) => {
      setGroups(groupsFetched);
      setUpdateData(false);
    });
  }, [ownerId, fetchAllGroups, addingNewGroup, updateData]);
  const groupsItems = Object.values(groups);

  const toggleDisplay = (val: any) => {
    setListState(val.detail.value);
  };

  return (
    <>
      <IonCard>
        <IonCardHeader color="favorite">
          <IonCardTitle>Assignments Admin</IonCardTitle>
        </IonCardHeader>
      </IonCard>

      <IonCard>
        <IonSegment value={listState} onIonChange={toggleDisplay}>
          <IonSegmentButton value="active">Active groups</IonSegmentButton>
          <IonSegmentButton value="inactive">Inactive groups</IonSegmentButton>
        </IonSegment>
      </IonCard>
      <IonItem>
        <IonSearchbar
          value={query}
          onIonChange={(e) => {
            setQuery(e.detail.value || "");
          }}
        ></IonSearchbar>
      </IonItem>
      <IonList>
        {listState === "active"
          ? groupsItems
              .filter((i) => i.active)
              .filter((i) =>
                i.title.toLowerCase().includes(query.toLowerCase())
              )
              .map(
                (group) =>
                  !addingNewGroup && (
                    <GroupItem
                      onComplete={() => {
                        setUpdateData(true);
                      }}
                      key={group.id}
                      group={group}
                    />
                  )
              )
          : groupsItems
              .filter((i) => !i.active)
              .filter((i) =>
                i.title.toLowerCase().includes(query.toLowerCase())
              )
              .map(
                (group) =>
                  !addingNewGroup && (
                    <GroupItem
                      onComplete={() => {
                        setUpdateData(true);
                      }}
                      key={group.id}
                      group={group}
                    />
                  )
              )}
      </IonList>

      {!addingNewGroup ? (
        <IonButton
          className="ion-margin-start"
          color="warning"
          onClick={() => setIsAddingNewGroup(true)}
        >
          Add a Group
        </IonButton>
      ) : (
        <>
          <IonItem>
            <IonLabel position="stacked">Group Name</IonLabel>
            <IonInput
              placeholder="Enter Group Name"
              onIonChange={(e) => setNewGroupTitle(e.detail.value!)}
            />
          </IonItem>
          <IonItem lines="none">
            {authorId && (
              <IonButton
                size="default"
                onClick={() => {
                  const newUuid = uuidv4();
                  const newGroup: Group = {
                    authorId: authorId || "",
                    id: newUuid,
                    title: newGroupTitle,
                    description: "",
                    members: [authorId || ""],
                    active: true,
                    invites: [],
                    path: "",
                    ownerId,
                  };
                  ownerId &&
                    createGroup(ownerId, authorId, newUuid, newGroupTitle);
                  setNewGroupTitle("");
                  setQuery(newGroupTitle);
                  setIsAddingNewGroup(false);
                  setGroups([...groups, newGroup]);
                  observeGroup(newUuid);
                }}
              >
                Save
              </IonButton>
            )}
          </IonItem>
        </>
      )}
    </>
  );
};

export default TeacherAdmin;

import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonItem, IonItemDivider, IonLabel, IonLoading } from '@ionic/react';
import React, { useRef, useState } from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import Content from './Content';
import firebase from 'firebase/compat/app';

interface TransmitProps { }

const ConvertPage: React.FC<TransmitProps> = () => {
    const uploadRef = useRef<any>(null)
    const [convertedUri, setConvertedUri] = useState<string>()
    const [status, setStatus] = useState<string>()
    return (
        <Content className="chill">
            <IonCard >
                <IonCardHeader>
                    <IonCardTitle>
                        m4a-mp3 Converter
                    </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    <IonItem>
                        <IonLabel position="stacked" color="primary">Audio Media</IonLabel>
                        <input style={{ width: "100%", height: "100%" }} ref={uploadRef} type="file" />
                    </IonItem>
                    <IonItemDivider color="light" />
                    {status === "converting" && <IonLoading message="Converting" isOpen={status === "converting"} />}

                    <IonButton disabled={status === "converting"} onClick={() => {
                        const file = uploadRef.current.files[0]
                        if (!file) {
                            return;
                        }
                        const extension = file.name.split(".").pop()
                        const song_name = 'song' + new Date().getMilliseconds()
                        const file_name = song_name + "." + extension;
                        const audioExtensions = [
                            // Uncompressed
                            'wav', 'aiff', 'au', 'pcm', 'raw',
                            // Lossless compressed
                            'flac', 'ape', 'alac', 'wv',
                            // Lossy compressed
                            'mp3', 'aac', 'ogg', 'wma', 'opus', 'm4a',
                            // Professional/Studio
                            'mpc', 'dss', 'dvf', 'gsm', 'awb'
                        ];
                        const formated_type = !file.type.startsWith('audio/')?"mp3":"mp4";
                        const media_file_name = song_name + "_output."+formated_type;
                        const storageRef = firebase.storage().ref(file_name);
                        setStatus("converting to "+formated_type);
                        storageRef.put(file).then(() => {
                            const waitForConversion = setInterval(() => {
                                firebase.storage().ref(media_file_name).getDownloadURL().then((uri) => {
                                    console.log(uri);
                                    if (uri) {
                                        setStatus("converted")
                                        setConvertedUri(uri);
                                        clearInterval(waitForConversion);
                                    }
                                });

                            }, 2000)
                        });
                    }} >
                        Convert
                    </IonButton>

                    {convertedUri && < IonButton color="success" onClick={() => {
                        window.open(convertedUri);
                    }}>
                        Download Converted File
                    </IonButton>}
                </IonCardContent>
            </IonCard>
        </Content >
    );
};

export default ConvertPage;

// function v4(): string | undefined {
//     throw new Error('Function not implemented.');
// }

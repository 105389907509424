import {
  IonAvatar,
  IonButton,
  IonCardContent,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonItemDivider,
  IonLabel,
  IonLoading,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonText,
  useIonViewWillEnter,
  useIonViewWillLeave
} from "@ionic/react";
import { globeOutline, personAddOutline, personRemove } from "ionicons/icons";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { withRouter } from "react-router";

import ChangeUserRole from "../components/ChangeUserRole";
import PersonalAlbums from "../components/PersonalAlbums";
import SongList from "../components/SongList";
import Spacer from "../components/Spacer";

import { useArtists } from "../data/store/useArtists";
import { useFavorites } from "../data/store/useFavorites";
import { useProfile } from "../data/store/useProfile";
import { useRoles } from "../data/store/useRoles";
import { useSongs } from "../data/store/useSongs";
import { useTitle } from "../data/store/useTitle";

import get from "lodash.get";
import CompactSongList from "../components/CompactSongList";
import { CompactToggle } from "../components/CompactToggle";
import { app } from "../services/LoginService";
// import {
//   warningEmptyPersonalSongs,
//   warningEmptyUserSongs,
// } from "../util/constants";
import {
  externalLink,
  sanitize,
  useIdParam,
  useProgressiveImage,
} from "../util/sugar";
import "./Avatar.scss";

import firebase from "firebase/compat/app";
import 'firebase/compat/firestore';
import { useRegions } from "../data/store/useRegions";

const ArtistDetail: React.FC = (props) => {
  const id = useIdParam(props);
  const { fetchSongsForArtist, status } = useSongs();
  const { fetchMeta, shouldFetchMeta } = useArtists();
  const { ownerId, authorId, compact } = useProfile();
  const artist = useArtists((x) => x.retrieve(id));
  const songs = useSongs((x) => x.retrieveByArtist(id));
  const artistsSongsNumber = useSongs((x) => x.artistNumberSongs);
  const setTitle = useTitle((x) => x.setTitle);
  const { isFollowing, follow, unfollow } = useFavorites();
  const { role } = useRoles((state) => state);

  const [songsList, setSongsList] = useState(songs);
  const [loadedArtistId, setLoadedArtistId] = useState("");
  const [avatarStatus, setAvatarStatus] = useState("hidden");
  const [artistState, setArtistState] = useState("songs");
  const bgRef = useRef<HTMLIonContentElement>(null);
  const following = isFollowing(id);
  const totalSongsNumber = artistsSongsNumber[id];
  const artistWall = artist && artist.wall;
  const alias = artist && artist.alias;
  const region = useRegions(x=>artist?.region?x.regions[artist?.region]:"")

  const hasDashboard = get(role, "authority", []).some((item) => {
    return item === "moderator" || item === "admin" || item === "super admin";
  });
  const current = loadedArtistId === id;
  // const isLoading = artistStatus === "fetching" || status === "fetching";
  const userIsOwner = current && loadedArtistId !== authorId;
  // const warningSongText = userIsOwner
  //   ? warningEmptyUserSongs
  //   : warningEmptyPersonalSongs;
  const loaded = useProgressiveImage(artistWall);

  const toggleArtistState = ({ detail: { value } }: any) => {
    setArtistState(value);
  };

  useIonViewWillLeave(() => {
    setLoadedArtistId("");
  });

  useLayoutEffect(() => {
    shouldFetchMeta(id) && fetchMeta(id);
    fetchSongsForArtist(id);
  }, [id]);

  useEffect(() => {
    alias && setTitle(alias);
  }, [alias, setTitle]);

  useEffect(() => {
    artist && setLoadedArtistId(artist.id);
  }, [artist]);

  useEffect(() => {
    setSongsList(songs);
  }, [songs]);
  useIonViewWillEnter(() => {
    firebase.analytics(app).logEvent("view_artist", id)
  }, [])
  return (
    <IonContent
      className={
        loaded ? "artist-wall sadfam-content" : "artist-bg sadfam-content"
      }
      style={{
        "--ion-background-color": "clear",
        background: `url(${loaded || ""})`,
      }}
      ref={bgRef}
    >
      <Spacer height={50} />
      <IonGrid>
        <IonLoading isOpen={status === "fetching" && (songsList || []).length === 0} />
        <IonRow>
          <IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
            <IonCardContent
              color="paper"
              style={{
                "backdropFilter": "blur(10px)",
                borderRadius: "8px",
                margin: "0 16px",
              }}
              className="centerColumn"
            >
              {current && artist && artist.avatar && (
                <IonAvatar
                  className={`${avatarStatus} avatarBorder`}
                  style={{
                    width: 108,
                    height: 108,
                  }}
                >
                  <IonImg
                    onIonImgDidLoad={() => setAvatarStatus("loaded")}
                    src={artist && artist.avatar}
                    alt={artist && alias}
                  />
                </IonAvatar>
              )}

              <IonCardTitle className="ion-text-center">
                <IonText
                  color="dark"
                  style={{
                    textShadow: "none",
                  }}
                >
                  {current && artist && artist.statement}
                </IonText>
                
              </IonCardTitle>
              {region&&<IonButton expand="full" fill='clear' routerLink={"/region-artists/"+region.id}>
        {region.city}
      </IonButton>}

              {current && artist && artist.website && (
                <a
                  className="avatarLink"
                  rel="noopener noreferrer"
                  target="_blank"
                  href={externalLink(sanitize(artist.website))}
                >
                  <IonText color="tertiary">{artist.website}</IonText>

                  <IonIcon
                    color="tertiary"
                    icon={globeOutline}
                    style={{ margin: "auto 10px" }}
                  />
                </a>
              )}

              <IonItemDivider color="clear" />
              <CompactToggle />
              {userIsOwner && (
                <IonButton
                  fill="outline"
                  onClick={() =>
                    following ? unfollow(ownerId, id) : follow(ownerId, id)
                  }
                >
                  {following ? (
                    <IonIcon slot="start" color="primary" icon={personRemove} />
                  ) : (
                    <IonIcon slot="start" icon={personAddOutline} />
                  )}
                  <IonLabel>
                    {following ? "Unfollow" : "Follow"} {alias}
                  </IonLabel>
                </IonButton>
              )}

              {hasDashboard && (
                <div style={{ margin: 10 }}>
                  <ChangeUserRole id={id} />
                </div>
              )}

              <IonSegment color="medium" value={artistState} onIonChange={toggleArtistState}>
                <IonSegmentButton value="songs">Songs</IonSegmentButton>
                <IonSegmentButton value="albums">Albums</IonSegmentButton>
              </IonSegment>
            </IonCardContent>

            {artistState === "songs" && current && songsList ? (
              <>
                {!compact ? <SongList
                  status={status}
                  songRefs={songsList}
                  hideFetchButton={songsList.length === totalSongsNumber}
                  fetchMore={() => fetchSongsForArtist(id)}
                /> : <CompactSongList status={status} songRefs={songsList}
                  hideFetchButton={songsList.length === totalSongsNumber}
                  fetchMore={() => {
                    fetchSongsForArtist(id)
                  }} />}
              </>
            ) : (
              <>
                <Spacer height={30} />
                {status !== "fetching" && <PersonalAlbums id={(artist && artist.ownerId) || ""} />}
              </>
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default withRouter(ArtistDetail as any);

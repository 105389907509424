import React, { useRef, useState } from "react";
import {
  IonButton,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonProgressBar,
  IonIcon,
  IonText,
} from "@ionic/react";
import {
  closeOutline,
  musicalNotes,
  videocam,
  refreshCircleOutline,
} from "ionicons/icons";

import { ArtistId } from "../models/Artist";
import { MediaReference, Song } from "../models/Song";
import isEmpty from "lodash.isempty";
import get from "lodash.get";
import { uuidv4 } from "../util/sugar";

import "react-dropzone-uploader/dist/styles.css";
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';

interface StateProps {
  ownerId?: ArtistId;
}

interface OwnProps {
  message?: string;
  collection: string;
  initialSong?: Song;
  onToggle?: () => void;
  onComplete: (media: MediaReference) => void;
}

interface DispatchProps { }

interface UploaderProps extends OwnProps, StateProps, DispatchProps { }

const ConvertingUploader: React.FC<UploaderProps> = ({
  onComplete,
  onToggle = () => { },
  initialSong = {},
}) => {
  const isinitialSongExist = !isEmpty(initialSong);
  const [isinitialSong, setinitialSong] = useState(isinitialSongExist);
  const [isShowDownloadInput, setIsShowDownloadInput] = useState(
    !isinitialSongExist
  );
  const [status, setStatus] = useState<string>();
  const [mediaType, setMediaType] = useState<'audio' | 'video'>();
  const uploadRef = useRef<any>();
  const [progress, setProgress] = useState(0);
  const [convertProgress, setConvertProgress] = useState(0);
  const [estimatedTime, setEstimatedTime] = useState(0);
  const [elapsedTime, setElapsedTime] = useState(0);
  
  const timerRef = useRef<NodeJS.Timeout>();
  const renderTitle = () => {
    if (isShowDownloadInput) {
      return <IonText color="success">Return previous media</IonText>;
    } else {
      const icon = mediaType === 'video' ? videocam : musicalNotes;
      return (
        <>
          <IonIcon icon={icon} style={{ paddingRight: 10 }} />
          <IonText color="success">{`${initialSong.title}.${get(
            initialSong,
            "media.type",
            mediaType === 'video' ? 'mp4' : 'mp3'
          )}`}</IonText>
        </>
      );
    }
  };
  const startConversionTimer = (fileSizeInBytes: number) => {
    const estimatedSeconds = Math.ceil(fileSizeInBytes / (1024 * 1024))+2048; // 1 second per MB
    setEstimatedTime(estimatedSeconds);
    setElapsedTime(0);
    
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    timerRef.current = setInterval(() => {
      setElapsedTime(prev => {
        const newTime = prev + 0.1;
        const newProgress = (newTime / estimatedSeconds) * 100;
        setConvertProgress(Math.min(newProgress, 99)); // Cap at 99% until complete
        
        if (newTime >= estimatedSeconds) {
          timerRef.current&&clearInterval(timerRef.current);
        }
        return newTime;
      });
    }, 100);
  };


  const toggleEdit = () => {
    onToggle();
    setIsShowDownloadInput((prevState) => !prevState);
  };

  const isVideoFile = (fileName: string): boolean => {
    const videoExtensions = ['mp4', 'mov', 'mpeg', 'quicktime'];
    const extension = fileName.split('.').pop()?.toLowerCase() || '';
    return videoExtensions.includes(extension);
  };

  const handleChange = () => {
    const file = uploadRef.current.files[0];
    setinitialSong(false);

    if (!file) {
      return;
    }

    const isVideo = isVideoFile(file.name);
    setMediaType(isVideo ? 'video' : 'audio');
    
    const extension = file.name.split(".").pop();
    const media_name = "media" + uuidv4();
    const file_name = media_name + "." + extension;
    
    // Define the converted file name based on media type
    let converted_file_name = isVideo 
      ? `${media_name}.mp4`
      : `${media_name}_output.mp3`;
    if(extension==='mp3'){
      converted_file_name = `${media_name}.mp3`
    }
    const storageRef = firebase.storage().ref(file_name);

    setStatus("uploading");

    const uploadTask = storageRef.put(file)
    uploadTask.then(() => {
      setStatus("converting");
      startConversionTimer(file.size);

      const waitForConversion = setInterval(() => {
        try{
        firebase
          .storage()
          .ref(converted_file_name)
          .getDownloadURL()
          .then((uri) => {
            if (uri) {
              setStatus("uploaded");
              onComplete({
                src: uri,
                type: isVideo ? "video/mp4" : "audio/mpeg",
              });
              clearInterval(waitForConversion);
            }
          });
        }catch(e:any){
          console.error(e);
        }
      }, 2000);
    });
    uploadTask.on('state_changed',(snapshot)=>{
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      setProgress(progress);
    });
  };

  return (
    <>
      <IonItem>
        <IonLabel position="stacked" color="primary">
          Media Upload
        </IonLabel>

        {isinitialSong && (
          <IonButton
            onClick={toggleEdit}
            size="default"
            color="dark"
            fill="clear"
          >
            {renderTitle()}

            {isShowDownloadInput ? (
              <IonIcon
                icon={refreshCircleOutline}
                size="big"
                style={{ paddingLeft: 10 }}
              />
            ) : (
              <IonIcon
                icon={closeOutline}
                size="big"
                style={{ paddingLeft: 10 }}
              />
            )}
          </IonButton>
        )}

        {isShowDownloadInput && (
          <input
            accept="audio/mp3,audio/wav,audio/m4a,audio/x-m4a,audio/x-aac,video/mp4"
            onChange={handleChange}
            style={{ width: "100%", height: "100%" }}
            ref={uploadRef}
            type="file"
          />
        )}
      </IonItem>

      <IonItemDivider color="light">
        {status} {mediaType && `(${mediaType})`}
      </IonItemDivider>

      {status === "uploading" && (
        <IonProgressBar color="tertiary" value={progress / 100}  />
      )}
      {status === "converting" && (
        <IonProgressBar color="success" value={convertProgress / 100} />
      )}   
      
      {status === "uploaded" && (
        <IonProgressBar color="success" value={ 100} />
      )}   
       </>
  );
};

export default ConvertingUploader;
import React, { useEffect } from "react";
import { CreateAnimation, IonItem, IonSearchbar } from "@ionic/react";

interface AnimatedSearchBarProps {
  isOpen: boolean;
  onChange: (value: string) => void;
}

const AnimatedSearchBar: React.FC<AnimatedSearchBarProps> = ({
  isOpen,
  onChange,
}) => {
  useEffect(() => {
    !isOpen && onChange("");
  }, [isOpen]);

  return isOpen ? (
    <CreateAnimation
      duration={150}
      children={
        <IonItem>
          <IonSearchbar onIonChange={(e) => onChange(e.detail.value || "")} />
        </IonItem>
      }
      play={isOpen}
      fromTo={[
        {
          property: "transform",
          fromValue: "translateY(-60%)",
          toValue: "translateY(0)",
        },
        {
          property: "opacity",
          fromValue: "0",
          toValue: "1",
        },
      ]}
    />
  ) : (
    <></>
  );
};

export default AnimatedSearchBar;

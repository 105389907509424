import sha1 from "crypto-js/sha1";
import 'firebase/compat/firestore';
import { Artist, ArtistId } from "../models/Artist";
import { Song } from "../models/Song";
import { EndOfTime, Timestamp } from "../models/Timeline";
import { OwnerId } from "../models/User";
import handleError from "../util/handleError";
import { SongService } from "./SongService";

import 'firebase/compat/firestore';
import firebase from "firebase/compat/app";
const firestore = firebase.firestore

export class ArtistService {
  /**
   * Fetch latest song uploaded by artist
   * @param ownerId
   */
  static fetchLatestSong(ownerId: OwnerId): Promise<Song> {
    const db = firestore();
    return new Promise((resolve, reject) => {
      db.collection("songs")
        .where("ownerId", "==", ownerId)
        .orderBy("updatedAt")
        .limitToLast(1)
        .get()
        .then((songDocs) => {
          const latestSongData = songDocs.docs.pop();
          if (latestSongData) {
            resolve(SongService.StronglyTypeSongData(latestSongData));
          } else {
            resolve({} as Song);
          }
        })
        .catch(reject);
    });
  }

  static transmitArtist = (artist: Artist) => {
    var db = firestore();
    return db.collection("artists").doc(artist.id).set(artist);
  };
  /**
   * fetch artist with meta data
   * @param id artist id
   */
  static fetch = (id: string) => {
    return new Promise<Artist>((resolve, reject) => {
      var db = firestore();
      if (typeof id === undefined || id === "" || typeof id != "string") {
        return reject('bad artist id');
      }
      db.collection("artists")
        .doc(id)
        .get()
        .then((artistDoc) => {
          
          const artist = ArtistService.StronglyTypeArtistData(artistDoc);
          if (artistDoc.exists) {
            resolve(artist);
          } else {
            reject({message:"not-found"});
          }
        });
    });
  };
  /**
   * Fetch multiple artists by their ownerIds in batches
   * @param ownerIds Array of owner IDs to fetch
   * @param batchSize Size of each batch (default 30)
   */
  static fetchMultipleByOwnerIds = async (
    ownerIds: string[],
    batchSize: number = 30
  ): Promise<Artist[]> => {

  // Utility function to chunk array into smaller arrays
const chunk:any = <T>(array: T[], size: number): T[][] => {
  return Array.from({ length: Math.ceil(array.length / size) }, (_, i) =>
    array.slice(i * size, i * size + size)
  );
};

    try {
      // Remove any invalid ownerIds
      const validOwnerIds = ownerIds.filter(
        (id): id is string => 
        typeof id === "string" && id !== "" && id !== undefined
      );

      // Split ownerIds into chunks
      const batches = chunk(validOwnerIds, batchSize);
      
      // Process each batch
      const batchResults = await Promise.all(
        batches.map(async (batchIds:any) => {
          const batchPromises = batchIds.map((ownerId:any) =>
            ArtistService.fetchByOwnerId(ownerId).catch((error) => {
              console.error(`Error fetching artist for ownerId ${ownerId}:`, error);
              return null; // Return null for failed fetches
            })
          );
          
          return Promise.all(batchPromises);
        })
      );

      // Flatten results and filter out nulls
      return batchResults
        .flat()
        .filter((artist:any): artist is Artist => artist !== null);
    } catch (error) {
      console.error("Error in batch fetching artists:", error);
      throw error;
    }
  };

 /**
   * Fetch multiple artists by their ownerIds in batches
   * @param ownerIds Array of owner IDs to fetch
   * @param batchSize Size of each batch (default 30)
   */
 static fetchMultipleByArtistIds = async (
  artistIds: string[],
  batchSize: number = 30
): Promise<Artist[]> => {

// Utility function to chunk array into smaller arrays
const chunk:any = <T>(array: T[], size: number): T[][] => {
return Array.from({ length: Math.ceil(array.length / size) }, (_, i) =>
  array.slice(i * size, i * size + size)
);
};

  try {
    // Remove any invalid ownerIds
    const validArtistIds = artistIds.filter(
      (id): id is string => 
      typeof id === "string" && id !== "" && id !== undefined
    );

    // Split ownerIds into chunks
    const batches = chunk(validArtistIds, batchSize);
    
    // Process each batch
    const batchResults = await Promise.all(
      batches.map(async (batchIds:any) => {
        const batchPromises = batchIds.map((artistId:any) =>
          ArtistService.fetch(artistId).catch((error) => {
            console.error(`Error fetching artist for artistId ${artistId}:`, error);
            return null; // Return null for failed fetches
          })
        );
        
        return Promise.all(batchPromises);
      })
    );

    // Flatten results and filter out nulls
    return batchResults
      .flat()
      .filter((artist:any): artist is Artist => artist !== null);
  } catch (error) {
    console.error("Error in batch fetching artists:", error);
    throw error;
  }
};

  /**
   * fetch artist with meta data
   * @param ownerId artist Ownerid
   */
  static fetchByOwnerId = (ownerId: string) => {
    return new Promise<Artist>((resolve, reject) => {
      var db = firestore();
      if (
        typeof ownerId === undefined ||
        ownerId === "" ||
        typeof ownerId != "string"
      ) {
        console.log("bad owner id");
        return;
      }
      db.collection("artists")
        .where("ownerId", "==", ownerId)
        .limit(1)
        .get()
        .then((artistDocs) => {
          const artistDoc = artistDocs.docs.pop();
          if (!artistDoc) {
            reject("error");
          } else {
            const artist = { ...artistDoc.data(), id: artistDoc.id } as Artist;
            if (artist) {
              resolve(artist);
            }
          }
        })
        .catch(handleError);
    }).catch(handleError);
  };

  /**
   * Fetch some songs
   *
   * @param artistId the artist ID
   * @param limit how many songs to get
   */
  static fetchSomeSongs = (artistId: ArtistId, limit: number) => {
    console.log("Getting songs for artist" + artistId);
    return new Promise<Song[]>((resolve) => {
      var db = firestore();
      db.collection("songs")
        .where("authors", "array-contains", artistId)
        .orderBy("updatedAt")
        .limitToLast(limit)
        .get()
        .then((querySnapshot) => {
          const songBatch = querySnapshot.docs.map((songDoc) => {
            return SongService.StronglyTypeSongData(songDoc);
          });
          resolve(songBatch);
        });
    });
  };

  static fetchArtistSongsNumber = (artistId: ArtistId) => {
    return new Promise<number>((resolve, reject) => {
      var db = firestore();
      db.collection("songs")
        .where("authors", "array-contains", artistId)
        .get()
        .then((data) => {
          const songsNumber = data.docs.length || 0;
          resolve(songsNumber);
          return songsNumber;
        })
        .catch(reject);
    });
  };

  /**
   * Fetch more songs of a particular artist
   *
   * @param artistId the artist ID
   * @param timestamp timestamp of an old song to start from
   * @param limit how many songs to get
   */
  static fetchMoreSongs = (
    artistId: ArtistId,
    timestamp: Timestamp,
    limit: number
  ) => {
    if (timestamp === EndOfTime || timestamp.seconds === -1) {
      return ArtistService.fetchSomeSongs(artistId, limit);
    }
    return new Promise<Song[]>((resolve, reject) => {
      var db = firestore();
      console.log("ITS HAPPENEING", timestamp);

      db.collection("songs")
        .where("authors", "array-contains", artistId)
        .orderBy("updatedAt")
        .endBefore(timestamp)
        .limitToLast(limit)
        .get()
        .then((querySnapshot) => {
          console.log(querySnapshot);
          const songBatch = querySnapshot.docs.map((songDoc) => {
            return SongService.StronglyTypeSongData(songDoc);
          });
          console.log(songBatch);
          resolve(songBatch);
        })
        .catch(reject);
    });
  };

  /**
   * Calculate Arist ID using SHA1 hash
   * Will we ever run into collisions?
   * think about migrating to another function to avoid them....
   * @param limit
   * @param dispatch
   */
  static calculateArtistId(email: string): string {
    return sha1(email).toString().substring(0, 11);
  }


  static fetchArtistsForRegion = (regionId: string, pageSize: number, offset: number=0): Promise<Artist[]> => {
    console.log(regionId,offset,pageSize,)
    return new Promise((resolve, reject) => {
      const db = firestore()
      const query = db.collection('artists')
        .orderBy("songs", "desc")
        .where('region', '==', regionId)
        .limit(pageSize)

      if (offset > 0) {
        // Get the last document from the previous page
        db.collection('artists')
          .orderBy("songs", "desc")
          .where('region', '==', regionId)
          .limit(offset)
          .get()
          .then((snapshot) => {
            const lastVisible = snapshot.docs[snapshot.docs.length - 1]
            // Start after the last document from previous page
            query.startAfter(lastVisible)
              .get()
              .then((querySnapshot) => {
                const artistBatch = querySnapshot.docs.map((songDoc) =>
                  ArtistService.StronglyTypeArtistData(songDoc),
                )
                resolve(artistBatch)
              })
              .catch((e) => {
                console.log(e)
                reject(e)
              })
          })
          .catch((e) => {
            console.log(e)
            reject(e)
          })
      } else {
        // First page, no offset needed
        query.get()
          .then((querySnapshot) => {
            console.log("Got batch")
            const artistBatch = querySnapshot.docs.map((artistDoc) =>
              ArtistService.StronglyTypeArtistData(artistDoc),
            )
            console.log(artistBatch);

            resolve(artistBatch)
          })
          .catch((e) => {
            console.log(e)
            reject(e)
          })
      }
    })
  }
  static StronglyTypeArtistData = (
    artistDoc:
      | firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
      | firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  ) => {
    const artist_data = { ...artistDoc.data(), id: artistDoc.id } as Artist;
    return artist_data;
  };
}

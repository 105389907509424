import React, { memo, useEffect, useMemo, useRef } from 'react';
// import * as THREE from "three";
// import { useFrame } from 'react-three-fiber';
// import { InstancedMesh } from 'three';
import MediaService from '../services/MediaService';
import THREE, { InstancedMesh, MeshStandardMaterial, Object3D, PlaneBufferGeometry } from 'three';
import { useFrame } from 'react-three-fiber';

interface OwnProps {
}

interface SwarmProps extends OwnProps { }

const Swarm: React.FC<SwarmProps> = () => {

    const geometryRef = useRef<PlaneBufferGeometry>(new PlaneBufferGeometry());
    const materialRef = useRef<MeshStandardMaterial>(new MeshStandardMaterial());
    const count = MediaService.please().getCircularWaveBufferSize();
    const mesh = useRef<InstancedMesh>()
    const mouseRef = useRef([0, 0]);
    useEffect(() => {

    });
    useEffect(() => {
        const updateMousePosition = (ev: any) => {
            mouseRef.current = ([ev.clientX - window.innerWidth / 2, ev.clientY - window.innerHeight / 2]);
        };
        window.addEventListener("mousemove", updateMousePosition);

    })



    const o = useMemo(() => new Object3D(), [])
    useFrame(() => {
        if (!MediaService.please().beReady()) {
            return;
        }
        MediaService.please().getLatestWaveFormData();
        MediaService.please().getLatestFrequencyData();
        const waveBuffers = MediaService.please().getWaveBuffers()
        const activeBufferIndex = MediaService.please().getActiveWaveBufferIndex();
        const totalBuffers = waveBuffers.length;
        const circularBufferLength = MediaService.please().getCircularWaveBufferSize();;
        const bufferSize = MediaService.please().getAnalyzerNode().frequencyBinCount;;
        let i = 0;
        let bufferIndex = activeBufferIndex;
        while (i < circularBufferLength) {
            i++;
            bufferIndex = (activeBufferIndex + Math.floor(i / bufferSize)) % totalBuffers;
            const w = waveBuffers[bufferIndex][i];
                        o.position.set(i / 4 - count / 16, w / 10, 0);
            // o.position.setFromSphericalCoords(i / 100, w / 40, i);
            o.scale.set(0.002*w, 0.002*w, 0.002*w);
            o.updateMatrix()
            // And apply the matrix to the instanced item
            mesh.current && mesh.current.setMatrixAt(i, o.matrix)
            if (mesh.current) {
                mesh.current.instanceMatrix.needsUpdate = true
            }
        }



    })
    return (
        <>
            <instancedMesh ref={mesh} count={count} args={[geometryRef.current, materialRef.current, count]}>
            </instancedMesh>
        </>
    )
};

export default memo(Swarm)

import React, { useEffect, useRef, useState } from 'react';
import MediaService from '../services/MediaService';
import { FC } from 'react';

const PlayerClone: FC<{ onClick: () => void }> = ({ onClick }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const animationFrameRef = useRef<number>();
  const [videoDimensions, setVideoDimensions] = useState({ width: 0, height: 0 });
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => document.removeEventListener('fullscreenchange', handleFullscreenChange);
  }, []);

  useEffect(() => {
    const mediaService = MediaService.please();
    const videoElement = mediaService.getVideoElement();

    const handleVideoMetadata = () => {
      if (videoElement) {
        setVideoDimensions({
          width: videoElement.videoWidth,
          height: videoElement.videoHeight
        });
      }
    };

    if (videoElement) {
      videoElement.addEventListener('loadedmetadata', handleVideoMetadata);
      if (videoElement.videoWidth) {
        handleVideoMetadata();
      }
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('loadedmetadata', handleVideoMetadata);
      }
    };
  }, []);

  useEffect(() => {
    const mediaService = MediaService.please();
    const videoElement = mediaService.getVideoElement();
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');

    if (!videoDimensions.width || !videoDimensions.height) return;

    if (canvas) {
      canvas.width = videoDimensions.width;
      canvas.height = videoDimensions.height;
    }

    const drawVideo = () => {
      if (videoElement && ctx && canvas) {
        ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
      }
      animationFrameRef.current = requestAnimationFrame(drawVideo);
    };

    drawVideo();

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [videoDimensions]);

  const wrapperStyle = {
    position: 'relative' as const,
    width: '100%',
    height: isFullscreen ? '100vh' : 'auto',
    background: '#000',
    display: 'flex',
    justifyContent: 'center', // Centers the canvas horizontally
    alignItems: 'center', // Centers the canvas vertically
    overflow: 'hidden'
  };

  const canvasStyle = {
    width: '100%',
    height: 'auto',
    display: 'block',
    aspectRatio: videoDimensions.width && videoDimensions.height ? 
      `${videoDimensions.width} / ${videoDimensions.height}` : 
      undefined
  };

  return (
    <div 
      onClick={onClick}
      ref={containerRef} 
      style={wrapperStyle}
    >
      <canvas
        ref={canvasRef}
        style={canvasStyle}
      />
    </div>
  );
};

export default PlayerClone;
import {
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonItem,
  IonList,
  IonRow,
  IonSpinner,
} from "@ionic/react";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useParams } from "react-router";
import { useArtists } from "../data/store/useArtists";
import { useConfig } from "../data/store/useConfig";
import { useRegions } from "../data/store/useRegions";

import isEmpty from "lodash.isempty";
import { useProfile } from "../data/store/useProfile";
import { usePageTitle } from "../util/sugar";
import ArtistItem from "./ArtistItem";
import Content from "./Content";

export const RegionArtists: React.FC = () => {
  const { compact } = useProfile();
  const { id, regionId } = useParams<{id:string, regionId:string}>();
  const  {regions}= useRegions()
  const { status, regionArtists, artists, fetchRegion, } = useArtists();
  const region = regions[regionId];
  const groups = useConfig((x) => x.activeMemberships());
  const isAlumni = useConfig((x) => x.isAlumni());
  const [fetched, setFetched] = useState<string>();
  const [page, setPage] = useState<number>(1);
    const artistsForRegion= regionArtists[regionId];
  useEffect(()=>{
        fetchRegion(regionId)
    },[regionId])

  const refreshPage = () => {
    window.location.reload();
  };

  usePageTitle(region ? region.city : "Loading");

  useLayoutEffect(() => {
    if (!regionId) {
      refreshPage();
    }
  }, [regionId]);


  useEffect(() => {
    if (status === "fetching") return;

    const fetchNext = regionId + "-" + page;

    if (
      typeof id !== "undefined" &&
      typeof regionId !== "undefined" &&
      fetched !== fetchNext
    ) {
      setFetched(fetchNext);
      fetchRegion(regionId);
    }
    // eslint-disable-next-line
  }, [
    id,
    groups,
    fetchRegion,
    status,
    fetched,
    isAlumni,
    regionId,
  ]);

  return (
    <Content classname="chill">
        <IonButton expand="full" fill="clear" routerLink={"/region-songs/"+regionId}>View Songs in {regions[regionId].city}</IonButton>
        <IonGrid>
        <IonRow>
          {status === "fetching" && (
            <IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
              <IonRow className="ion-justify-content-center">
                <IonSpinner name="dots" />
              </IonRow>
            </IonCol>
          )}

          {status === "idle" &&
            regionArtists &&
            (artistsForRegion?artistsForRegion:[]).length === 0 && (
              <IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
                <IonCard>
                  <IonItem>
                    No Artists found for this region ~
                    <IonButton onClick={refreshPage}>Refresh</IonButton>
                  </IonItem>
                </IonCard>
              </IonCol>
            )}
        </IonRow>

        <IonRow>
          {!isEmpty(artistsForRegion) && <IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
            <IonList>
              {artistsForRegion.map(artistId => (
                <ArtistItem key={artistId} id={artistId} />
              ))}
                <IonButton fill='outline' expand='full' onClick={() => setPage(x=>x+1)}>
                  Load More
                </IonButton>
            </IonList>

          </IonCol>}
        </IonRow>
      </IonGrid>
    </Content>
  );
};

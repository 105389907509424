import { IonBadge, IonButton, IonCol, IonGrid, IonItem, IonList, IonLoading, IonRow } from '@ionic/react';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import React, { useEffect, useMemo, useState } from 'react';
import Content from './components/Content';
import { useProfile } from './data/store/useProfile';
import { useRegions } from './data/store/useRegions';
import { usePageTitle } from './util/sugar';

interface RegionListProps { }

const RegionList: React.FC<RegionListProps> = () => {
  const { regions } = useRegions();
  const [regionCounts, setRegionCounts] = useState<Record<string, number>>({});
  const [loadingCounts, setLoadingCounts] = useState(true);

  usePageTitle('Regions');
  const unauthenticated = useProfile(x => x.status === 'unauthenticated');
  const authorId = useProfile(x => x.authorId);
  const [limit, setLimit] = useState<number>(10);
  const pageSize = 20;

  useEffect(() => {
    const fetchRegionCounts = async () => {
      const db = getFirestore();
      
      try {
        const statsRef = doc(db, 'stats', 'regions');
        const statsDoc = await getDoc(statsRef);
        
        if (statsDoc.exists()) {
          setRegionCounts(statsDoc.data() || {});
        } else {
          console.log('No region stats found');
          setRegionCounts({});
        }
      } catch (error) {
        console.error('Error fetching region counts:', error);
        setRegionCounts({});
      } finally {
        setLoadingCounts(false);
      }
    };

    fetchRegionCounts();
  }, []);

  // Sort and filter regions based on region counts
  const sortedRegions = useMemo(() => {
    return Object.values(regions)
      .filter(region => regionCounts[region.id] > 0)
      .sort((a, b) => {
        const countA = regionCounts[a.id] || 0;
        const countB = regionCounts[b.id] || 0;
        return countB - countA; // Sort in descending order
      });
  }, [regions, regionCounts]);

  return (
    <Content className='chill'>
      <IonGrid>
        <IonRow>
          <IonLoading isOpen={loadingCounts} />
          <IonCol sizeMd='6' offsetMd='3' sizeXs='12'>
            <IonList>
              {sortedRegions.slice(0, limit).map(({city, id}) => (
                <IonItem key={id} routerLink={`/region-artists/${id}`}>
                  {city}
                  <IonBadge slot="end" color="primary">
                    {regionCounts[id] || 0} {regionCounts[id] === 1 ? 'artist' : 'artists'}
                  </IonBadge>
                </IonItem>
              ))}
              {sortedRegions.length > limit && (
                <IonButton fill='outline' expand='full' onClick={() => setLimit(x => x + pageSize)}>
                  Load More
                </IonButton>
              )}
            </IonList>
          </IonCol>
        </IonRow>
      </IonGrid>
    </Content>
  );
};

export default RegionList;
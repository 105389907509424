import {
  IonContent,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonToggle,
  isPlatform,
} from "@ionic/react";
import {
  schoolSharp,
  cafeOutline,
  leafOutline,
  logIn,
  logOut,
  moonOutline,
  musicalNoteOutline,
  peopleOutline,
  person,
  radioOutline,
  starOutline,
  musicalNotesOutline,
  listOutline,
  folderOpenOutline,
  globeOutline,
} from "ionicons/icons";
import React from "react";
import { RouteComponentProps, useLocation, withRouter } from "react-router";
import { useProfile } from "../data/store/useProfile";
import HostConfig from "../HostConfig";
import "./Menu.css";

export const LeftSidePages: Pages[] = [
  { title: "", path: "/", icon: musicalNoteOutline, routerDirection: "back" },
  {
    title: "",
    path: "/artists",
    icon: peopleOutline,
    routerDirection: "forward",
  },
];

const SideMenuPages: Pages[] = [
  {
    title: "Mission",
    path: "/mission",
    icon: leafOutline,
    routerDirection: "back",
  },
  {
    title: "Artists",
    path: "/artists",
    icon: peopleOutline,
    routerDirection: "forward",
  },
  {
    title: "Song Feed",
    path: "/feed",
    icon: musicalNotesOutline,
    routerDirection: "forward",
  },
];

const TopLoggedInPages: Pages[] = [
  {
    title: "",
    path: "/transmit",
    icon: radioOutline,
    routerDirection: "forward",
  },
  { title: "", path: "/account", icon: person, routerDirection: "forward" },
  {
    title: "",
    path: "/conversations",
    icon: cafeOutline,
    routerDirection: "forward",
  },
  // { title: 'Notices', path: '/notices', icon: alertOutline },
  { title: "", path: "/logout", icon: logOut, routerDirection: "root" },
];

const LoggedInPages: Pages[] = [
  {
    title: "Assignments",
    path: "/assignments",
    icon: schoolSharp,
    routerDirection: "forward",
  },
  {
    title: "Regions",
    path: "/regions",
    icon: globeOutline,
    routerDirection: "forward",
  },
  {
    title: "Transmit",
    path: "/transmit",
    icon: radioOutline,
    routerDirection: "forward",
  },
  {
    title: "Favorites",
    path: "/favorites",
    icon: starOutline,
    routerDirection: "root",
  },
  {
    title: "Playlists",
    path: "/playlists",
    icon: listOutline,
    routerDirection: "forward",
  },
  {
    title: "Albums",
    path: "/albums",
    icon: folderOpenOutline,
    routerDirection: "forward",
  },
  {
    title: "Conversations",
    path: "/conversations?thread=start",
    icon: cafeOutline,
    routerDirection: "forward",
  },
  {
    title: "Account",
    path: "/account",
    icon: person,
    routerDirection: "forward",
  },
  // { title: 'Support', path: '/support', icon: help },
  // { title: 'Notices', path: '/notices', icon: alertOutline },
  { title: "Logout", path: "/logout", icon: logOut, routerDirection: "root" },
];

const DesktopOnlyPages: Pages[] = [
  // { title: 'Visualizer', path: '/visualizer', icon: eyeOutline, routerDirection: "forward" }
];

const LoggedOutPages: Pages[] = [
  { title: "Login", path: "/login", icon: logIn, routerDirection: "forward" },
];

export const AllRoutes = {
  appPages: SideMenuPages,
  topMenuLoggedInPages: TopLoggedInPages,
  topLeftPages: LeftSidePages,
  loggedInPages: LoggedInPages,
  loggedOutPages: LoggedOutPages,
  desktopOnlyPages: DesktopOnlyPages,
};

export interface Pages {
  title: string;
  path: string;
  icon: string;
  routerDirection?: "back" | "forward" | "root" | undefined;
}

interface MenuProps extends RouteComponentProps {}

const Menu: React.FC<MenuProps> = () => {
  const { darkMode, authenticated, setDarkMode, menuEnabled } = useProfile();
  const location = useLocation();

  function renderListItems(list: Pages[]) {
    return list
      .filter((route) => !!route.path)
      .map((p) => (
        <IonMenuToggle key={p.title} auto-hide="false">
          <IonItem
            detail={false}
            routerLink={p.path}
            routerDirection="root"
            className={location.pathname === p.path ? "selected" : undefined}
          >
            <IonIcon slot="start" icon={p.icon} />
            <IonLabel>{p.title}</IonLabel>
          </IonItem>
        </IonMenuToggle>
      ));
  }

  return (
    <IonMenu
      menuId="primary"
      side="start"
      type="overlay"
      disabled={!menuEnabled}
      contentId="main"
    >
      <IonContent forceOverscroll={false}>
        <IonList lines="none">
          <IonListHeader>{HostConfig.title}</IonListHeader>
          {renderListItems(AllRoutes.appPages)}
        </IonList>
        <IonItemDivider/>
        <IonList lines="none">
          {!isPlatform("ios") && renderListItems(AllRoutes.desktopOnlyPages)}
          {authenticated
            ? renderListItems(AllRoutes.loggedInPages)
            : renderListItems(AllRoutes.loggedOutPages)}
          <IonItem>
            <IonIcon slot="start" icon={moonOutline}></IonIcon>
            <IonLabel>Dark Mode</IonLabel>
            <IonToggle
              checked={darkMode}
              onClick={() => setDarkMode(!darkMode)}
            />
          </IonItem>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default withRouter(Menu);

import produce from "immer";
import create from "zustand";

import { ArtistId } from "../../models/Artist";
import { Configuration } from "../../models/Configuration";
import { Group, GroupId } from "../../models/Group";
import { ConfigurationService } from "../../services/ConfigurationService";
import { GroupService } from "../../services/GroupService";

export type ConfigState = {
  groupConfigurations: Record<GroupId, Configuration>;
  membership: GroupId[];
  members: ArtistId[];
  config?: Configuration;
  groups: Record<GroupId, Group>;
  allGroups: Group[];
  status: "unknown" | "observing";
  groupId: GroupId;
  group?: Group;
  changeGroup: (
    ownerId: string,
    groupId: string,
    property: string,
    value: any
  ) => void;
  createGroup: (ownerId: string, authorId: string, groupId: string, title: string) => void,


  changeConfig: (groupId: string, property: string, value: any) => void;
  deleteGroup: (groupId: string) => void;
  updateGroups: (ownerId: string, authorId: ArtistId) => void;
  fetchAllGroups: (ownerId: string) => Promise<Group[]>;
  fetchGroup: (groupId: string) => Promise<Group>;
  observe: (
    ownerId: string,
    authorId: ArtistId,
    observeAssignments: (groupId: string[]) => void
  ) => void;
  activeGroups: () => Group[];
  inactiveGroups: () => Group[];
  activeMemberships: () => GroupId[];
  isAlumni: () => boolean | undefined;
};

export const useConfig = create<ConfigState>((set, store) => ({
  groupId: "public",
  groupConfigurations: {},
  members: [],
  membership: [],
  groups: {},
  allGroups: [],
  status: "unknown",
  changeGroup: (ownerId, groupId, prop, value) => {
    let update: Record<string, any> = {};
    update[prop] = value;
    update["ownerId"] = ownerId;
    typeof value !== "undefined" && GroupService.changeGroup(groupId, update);
  },
  fetchAllGroups: () => {
    return GroupService.allGroups();
  },
  fetchGroup: (groupId) => {
    return GroupService.fetchGroup(groupId);
  },
  deleteGroup: (groupId: string) => {
    GroupService.deleteGroup(groupId).then(() => {
      const updatedGroups = { ...store().groups };
      delete updatedGroups[groupId];

      const updatedMembership = store().membership.filter(
        (item) => item !== groupId
      );

      set({ groups: updatedGroups, membership: updatedMembership });
    });
  },
  createGroup: (ownerId, authorId, groupId, title) => {
    let update: Record<string, any> = {};
    update['title'] = title;
    update['active'] = true;
    update["ownerId"] = ownerId;
    update["id"] = groupId;
    update["members"] = [authorId];
    GroupService.changeGroup(groupId, update);
    set({ allGroups: [...store().allGroups, update as Group] })
  },



  updateGroups: (ownerId: string, authorId: string) => {
    GroupService.observe(ownerId, authorId, (groupMemberShips) => {
      const updatedGroups: Record<GroupId, Group> = {};

      groupMemberShips.map((item) => (updatedGroups[item.id] = { ...item }));
      set({ groups: updatedGroups, status: "observing" });
    });
  },

  changeConfig: (groupId, prop, value) => {
    let update: Record<string, any> = {};
    update[prop] = value;
    ConfigurationService.changeConfiguration(update, groupId);
  },

  activeGroups: () => {
    const { membership, groups } = store();
    return membership
      ? membership.map((groupId) => groups[groupId]).filter((x) => x.active)
      : [];
  },
  inactiveGroups: () => {
    const { membership, groups } = store();
    return membership
      ? membership.map((groupId) => groups[groupId]).filter((x) => !x.active)
      : [];
  },

  activeMemberships: () => {
    const { membership, groups } = store();

    return membership
      ? membership
        .map((groupId) => groups[groupId])
        .filter((x) => x.active)
        .map((x) => x.id)
      : [];
  },

  isAlumni: () => {
    const { membership } = store();
    if (typeof membership === "undefined") {
      return undefined;
    } else {
      return membership.length !== 0;
    }
  },

  observe: (ownerId, authorId, observeAssignements) => {
    GroupService.observe(ownerId, authorId, (groupMemberShips) => {
      // console.log(groupMemberShips);
      let members: string[] = [...store().members];

      groupMemberShips.forEach((group) => {
        if (
          group &&
          typeof group.members !== "undefined" &&
          group.members.includes(authorId)
        )
          members = members.concat(group.members);
      });

      const groups = produce(store().groups, (draft) => {
        groupMemberShips.forEach((groupMembership) => {
          draft[groupMembership.id] = groupMembership;
        });
      });

      const membership = Array.from([
        ...new Set([
          ...(store().membership || []),
          ...groupMemberShips.map((x) => x.id),
        ]),
      ]);

      observeAssignements(membership);
      set({
        groups,
        membership,
        allGroups: groupMemberShips,
        members: Array.from([...new Set([...members])]),
      });
    });

    ConfigurationService.observe((configs) => {
      const newState = produce(store(), (draft) => {
        configs.forEach((config) => {
          if (store().groupId === config.id) {
            draft.config = config;
          }

          draft.groupConfigurations[config.id] = config;
        });
      });
      set({ ...newState });
    });
  },
}));

import firebase from 'firebase/compat/app';
import { RefObject } from 'react';
import { Song } from '../models/Song';
import { app } from "../services/LoginService";
declare const window: any;

const AudioContext = window.AudioContext || window.webkitAudioContext;

export default class MediaService {
  private static instance: MediaService;
  private context!: AudioContext;
  private videoElement!: HTMLVideoElement;
  private mediaSourceNode!: MediaElementAudioSourceNode;
  private analyzerNode!: AnalyserNode;
  private initialized: boolean = false;
  private frequencyData: Uint8Array[] = [];
  private waveFormData: Uint8Array[] = [];
  private frequencyBufferIndex: number = 0;
  private waveFormBufferIndex: number = 0;
  public progress = 0;
  public buffer = 0;
  public nowPlaying: string = '';
  private constructor() { }

  public static please(): MediaService {
    if (!MediaService.instance) {
      MediaService.instance = new MediaService();
    }
    return MediaService.instance;
  }

  public setPlayerRef(playerRef: RefObject<HTMLVideoElement>) {
    if (playerRef.current) {
      this.videoElement = playerRef.current;
    }
    if (window.AudioContext) {
      this.context = new AudioContext();
    }
  }

  public seek(percentage: number) {
    this.videoElement.currentTime = percentage * this.videoElement.duration
  }
  public isPlaying() {
   return !this.videoElement.paused
  }

  public updateBuffer() {
    const videoElement = this.videoElement;
    const time = videoElement.currentTime;
    const duration = videoElement.duration;
    this.progress = time / duration;
    if (duration > 0) {
      try {
        const bufferedEnd = videoElement.buffered.end(videoElement.buffered.length - 1);
        this.buffer = bufferedEnd / duration;
      } catch {
        this.buffer = 0;
      }
    }
  }

  public pause() {
    this.videoElement.pause();
  }

  public async play() {
    await this.videoElement.play();
  }

  public getContext() {
    return this.context;
  }

  public getVideoElement() {
    return this.videoElement;
  }

  public getMediaSourceNode() {
    return this.mediaSourceNode;
  }

  public getAnalyzerNode() {
    return this.analyzerNode;
  }

  public getDestinationNode() {
    return this.getDestinationNode;
  }

  public async setSong(song: Song) {
    console.log("Setting song",song);
    firebase.analytics(app).logEvent("play_song", { id: song.id });
    if (this.nowPlaying === song.id) {
      return;
    } else {
      this.nowPlaying = song.id;
    }
    if (this.videoElement) {
      this.videoElement.volume = 0;
      if(this.videoElement.src!=song.media.src){
        this.videoElement.src = song.media.src;
      }
      this.videoElement.volume = 1;
    }
  }

  public initializeAnalysis() {
    if (!this.initialized) {
      this.mediaSourceNode = this.context.createMediaElementSource(this.videoElement);
      this.analyzerNode = this.context.createAnalyser();
      this.mediaSourceNode.connect(this.analyzerNode);
      this.analyzerNode.connect(this.context.destination);
      this.initialized = true;
      this.frequencyData.push(new Uint8Array(this.analyzerNode.frequencyBinCount));
      this.waveFormData.push(new Uint8Array(this.analyzerNode.fftSize));
      this.context.resume();
    }
    return this.initialized;
  }

  public getWaveFormBufferSize() {
    return this.waveFormData.length * this.analyzerNode.fftSize;
  }

  public getLatestFrequencyData() {
    this.analyzerNode.getByteFrequencyData(this.frequencyData[this.frequencyBufferIndex]);
    this.frequencyBufferIndex = (this.frequencyBufferIndex + 1) % this.frequencyData.length;
  }

  public getLatestWaveFormData() {
    this.analyzerNode.getByteTimeDomainData(this.waveFormData[this.waveFormBufferIndex]);
    this.waveFormBufferIndex = (this.waveFormBufferIndex + 1) % this.waveFormData.length;
  }

  public getFrequencyBuffers() {
    return this.frequencyData;
  }

  public getWaveBuffers() {
    return this.waveFormData;
  }

  public getActiveFrequencyBufferIndex() {
    return this.frequencyBufferIndex;
  }

  public getActiveWaveBufferIndex() {
    return this.waveFormBufferIndex;
  }

  public getCircularWaveBufferSize() {
    return this.analyzerNode.fftSize * this.waveFormData.length;
  }

  public beReady() {
    return this.initialized;
  }
}

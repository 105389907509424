import { menuController } from "@ionic/core";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonProgressBar,
  IonRow,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import {
  chevronUp,
  closeOutline,
  listOutline,
  musicalNotesOutline,
  pause as pauseIcon,
  playBack,
  playForward,
  play as playIcon,
} from "ionicons/icons";
import React, { useEffect, useState } from "react";

import { usePlayer } from "../data/store/usePlayer";
import { usePlaylist } from "../data/store/usePlaylist";
import { useSongs } from "../data/store/useSongs";
import { useToasts } from "../data/store/useToasts";
import MediaService from "../services/MediaService";

import { Song } from "../models/Song";
import Visualizer from "../pages/Visualizer";
import useWindowSize from "../util/useWindowSize";
import AsyncArtistButton from "./AsyncArtistButton";
import Player from "./Player";
import PlayerClone from "./PlayerClone";
import FullScreenPlayerClone from "./FullScreenClone";

interface NowPlayingBottomBarProps {}

const NowPlayingBottomBar: React.FC<NowPlayingBottomBarProps> = () => {
  const { width } = useWindowSize();
  const lg = width > 767;
  const { isPaused } = usePlayer();
  const { songs, nextSong, previousSong, nowPlayingId } = usePlaylist();
  const nowPlaying = useSongs((x) => nowPlayingId && x.retrieve(nowPlayingId));
  const { retrieve } = useSongs();
  const { toasts } = useToasts();
  const removeToast = useToasts((x) => x.remove);
  const [firstLoad, setFirstLoad] = useState(true);
  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(0);
  const [displayedVideoSong, setDisplayVideoSong] = useState<string>("");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
      setInterval(() => {
        if (MediaService.please().getVideoElement()) {
          MediaService.please().updateBuffer();
          setProgress(MediaService.please().progress);
          setBuffer(MediaService.please().buffer);
        }
      }, 150);
    }
  }, [firstLoad]);

  const closeModal = () => setShowModal(false);

  const setAndPlay = (song: Song) => {
    MediaService.please()
      .setSong(song)
      .then(async () => {
        await MediaService.please().play();
      });
  };

  const playPrev = () => {
    const song = retrieve(previousSong());
    song && setAndPlay(song);
  };

  const playNext = () => {
    const song = retrieve(nextSong());
    song && setAndPlay(song);
  };
  // useEffect(() => {
  //   nowPlaying &&
  //     typeof nowPlayingId !== "undefined" &&
  //     nowPlaying.media.type.startsWith("video") &&
  //     displayedVideoSong != nowPlayingId &&
  //     (() => {
  //       setShowModal(true);
  //       setDisplayVideoSong(nowPlayingId);
  //     })();
  // }, [nowPlaying, displayedVideoSong]);

  return (
    <IonFooter slot="bottom">
      <IonToolbar
        style={{ paddingBottom: 10 }}
        onClick={() => !lg && nowPlaying && setShowModal(true)}
      >
        {!!toasts.length &&
          toasts.map((toast) => (
            <IonToast
              key={toast.id}
              isOpen={true}
              message={toast.message}
              duration={2000}
              position="top"
              onDidDismiss={() => removeToast(toast)}
            />
          ))}

        <IonButtons slot="primary">
          <Player
            onClick={() => {
              setShowModal(true);
            }}
          />
        </IonButtons>

        {lg ? (
          <>
            {nowPlaying && (
              <IonButtons slot="start">
                <IonButton
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  {nowPlaying.title}
                </IonButton>
                {nowPlaying.authors.map((author) => (
                  <AsyncArtistButton key={author} id={author} />
                ))}
              </IonButtons>
            )}
            <IonButtons slot="end">
              {nowPlaying && (
                <>
                  {songs.length > 1 && (
                    <IonButton onClick={playPrev}>
                      <IonIcon icon={playBack} />
                    </IonButton>
                  )}
                  {isPaused ? (
                    <IonButton onClick={() => MediaService.please().play()}>
                      <IonIcon icon={playIcon} />
                    </IonButton>
                  ) : (
                    <IonButton onClick={() => MediaService.please().pause()}>
                      <IonIcon icon={pauseIcon} />
                    </IonButton>
                  )}
                  {songs.length > 1 && (
                    <IonButton onClick={playNext}>
                      <IonIcon icon={playForward} />
                    </IonButton>
                  )}
                </>
              )}
              {songs.length > 0 && (
                <IonButton
                  onClick={async () => {
                    await menuController.enable(true, "playlist");
                    await menuController.open("playlist");
                  }}
                >
                  <IonIcon icon={listOutline} />
                </IonButton>
              )}
            </IonButtons>
          </>
        ) : (
          nowPlaying && (
            <>
              <IonItem lines="none" style={{ "--min-height": "38px" }}>
                <IonIcon
                  icon={musicalNotesOutline}
                  size="small"
                  style={{ marginRight: 4 }}
                />
                <IonLabel className="ion-no-margin">
                  {nowPlaying.title}
                </IonLabel>
              </IonItem>
              <IonButtons slot="end">
                <IonButton>
                  <IonIcon icon={chevronUp} />
                </IonButton>
              </IonButtons>
            </>
          )
        )}
      </IonToolbar>

      {nowPlaying && (
        <IonProgressBar
          color="favorite"
          style={{
            position: "absolute",
            bottom: 0,
            padding: 0,
            height: 10,
            margin: 0,
            zIndex: 15,
          }}
          value={progress}
          buffer={buffer}
          onClick={(e) => {
            if (lg) {
              const eventWidthPercentage = e.clientX / window.innerWidth;
              MediaService.please().seek(eventWidthPercentage);
            }
          }}
        />
      )}

      <IonModal
        cssClass={"now-playing-modal"}
        mode="ios"
        isOpen={showModal}
        swipeToClose
        onDidDismiss={closeModal}
      >
        <IonHeader>
          <IonToolbar color="secondary">
            <IonButtons slot="end">
              <IonButton color="dark" onClick={closeModal}>
                <IonIcon icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {nowPlaying && (
            <>
              {nowPlaying.media.type.startsWith("audio") ? (
                <Visualizer />
              ) : (
          <FullScreenPlayerClone
            onClick={() => {
              if(MediaService.please().isPlaying()){
                MediaService.please().pause();
              }else{
                MediaService.please().play();
              }
        }}
          />
              )}
            </>
          )}
        </IonContent>
        <IonFooter>
          {nowPlaying && (
            <>
              <IonItem lines="none">
                <IonButtons slot="start">
                  <IonButton
                    routerLink={"/songs/" + nowPlaying.id}
                    onClick={() => {
                      setShowModal(false);
                    }}
                  >
                    {nowPlaying.title}
                  </IonButton>
                </IonButtons>
              </IonItem>
              <IonItem lines="none">
                <IonButtons slot="start">
                  {nowPlaying.authors.map((author) => (
                    <AsyncArtistButton
                      key={author}
                      id={author}
                      onClick={() => {
                        setShowModal(false);
                      }}
                    />
                  ))}
                </IonButtons>
              </IonItem>
            </>
          )}

          {nowPlaying && (
            <div style={{ paddingLeft: 24, paddingRight: 24 }}>
              <IonProgressBar
                color="favorite"
                style={{ height: 10 }}
                value={progress}
                buffer={buffer}
                onClick={(e) => {
                  const eventWidthPercentage =
                    (e.clientX - 24) / e.currentTarget.clientWidth;
                  MediaService.please().seek(eventWidthPercentage);
                }}
              />
              <IonGrid className="ion-margin-top">
                <IonRow className="ion-align-items-center ion-justify-content-around ion-padding-top">
                  <IonButtons>
                    {songs.length > 1 && (
                      <IonButton
                        onClick={() => {
                          const song = retrieve(previousSong());
                          song &&
                            MediaService.please()
                              .setSong(song)
                              .then(async () => {
                                await MediaService.please().play();
                              });
                        }}
                      >
                        <IonIcon icon={playBack} size="large" />
                      </IonButton>
                    )}
                  </IonButtons>
                  <IonButtons>
                    {isPaused ? (
                      <IonButton onClick={() => MediaService.please().play()}>
                        <IonIcon icon={playIcon} size="large" />
                      </IonButton>
                    ) : (
                      <IonButton onClick={() => MediaService.please().pause()}>
                        <IonIcon icon={pauseIcon} size="large" />
                      </IonButton>
                    )}
                  </IonButtons>
                  <IonButtons>
                    {songs.length > 1 && (
                      <IonButton
                        onClick={() => {
                          const song = retrieve(nextSong());
                          song &&
                            MediaService.please()
                              .setSong(song)
                              .then(async () => {
                                await MediaService.please().play();
                              });
                        }}
                      >
                        <IonIcon icon={playForward} size="large" />
                      </IonButton>
                    )}
                  </IonButtons>
                </IonRow>
              </IonGrid>
            </div>
          )}
          <IonToolbar>
            <IonButtons slot="end">
              {!!songs.length && (
                <IonButton
                  onClick={async () => {
                    closeModal();
                    await menuController.enable(true, "playlist");
                    await menuController.open("playlist");
                  }}
                >
                  <IonIcon icon={listOutline} size="large" />
                </IonButton>
              )}
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </IonModal>
    </IonFooter>
  );
};

export default NowPlayingBottomBar;

import { IonLabel, IonToggle } from "@ionic/react";
import React from "react";
import { useProfile } from "../data/store/useProfile";
export const CompactToggle:React.FC=()=>{
    const { compact, setCompactMode} = useProfile();

    return <div className="sortWrapper">

        <IonLabel color={compact ? "primary" : "medium"}>
          Compact view
        </IonLabel>
        <IonToggle
        onIonChange={(e)=>{
          compact!=e.detail.checked&&setCompactMode(e.detail.checked)
        }}
          class="ion-padding-start"
          checked={compact}
        />
    </div>

}